import { UserCommonService } from './../../services/user-common.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { ErrorBindingService } from './../../services/error-binding.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html'
})
export class SetPasswordComponent implements OnInit {
  @Output() setPasswordPopClose: EventEmitter<any> = new EventEmitter();
  @Input() otpId;

  passwordError = [{
    message: false
  }];

  errorMessage: Message[] = [];

  setPasswordForm = new FormGroup({
    newPassword: new FormControl(),
    newConfirmPassword: new FormControl()
  });

  constructor(private formBuilder: FormBuilder,
    private signOutService: UserCommonService,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService) { }

  ngOnInit() {
    this.setPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
      newConfirmPassword: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]]
    });
  }


  passwordNotMatched() {
    if (this.setPasswordForm.controls.newPassword.value !== this.setPasswordForm.controls.newConfirmPassword.value) {
      const error = {
        general: [
          {
            message: this.commonService.getLabel('error_new_confirm_password_not_match')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(JSON.stringify(error), this.passwordError);
      return false;
    } else {
      return true;
    }
  }

  requestData() {
    return {
      otpId: this.otpId,
      password: this.setPasswordForm.controls.newPassword.value,
    };
  }

  doChangePassword() {
    const passwordVerified = this.passwordNotMatched();
    if (passwordVerified) {
      const data = this.requestData();
      const c = this;
      this.signOutService.setPassword(JSON.stringify(data)).subscribe(results => {
        this.commonService.showMessage('Success', results.general[0].message);
        setTimeout(() => {
          this.setPasswordForm.reset();
          this.setPasswordPopClose.emit(false);
        }, 2000);

        // if (results.general !== null && results.general !== undefined) {
        //   results.general.forEach(element => {
        //     this.errorMessage = [];
        //     this.errorMessage.push({ severity: 'success', summary: 'Success', detail: element.message });
        //   });
        //   setTimeout(() => {
        //     this.setPasswordForm.reset();
        //     this.setPasswordPopClose.emit(false);
        //   }, 2000);
        // }
      }, (error) => {
        this.commonService.showMessage('Warn', error.general[0].message, 'Error');
        // if (error.general !== null && error.general !== undefined) {
        //   error.general.forEach(element => {
        //     c.errorMessage = [];
        //     c.errorMessage.push({ severity: 'warn', summary: 'Error', detail: element.message });
        //   });
        // }
      });
    } else {
      // this.errorMessage = [];
      // this.errorMessage.push({ severity: 'error', summary: 'Error', detail: 'New password does not match with confirm new password.' });
      this.commonService.showMessage('Error', 'New password does not match with confirm new password.');
    }
  }

  onCancel() {
    this.setPasswordForm.reset();
    this.setPasswordPopClose.emit(false);
  }

}
