export class DestinationsModel {
  id = '';
  routeRate = 0;
  longitude = 0;
  latitude = 0;
  radius = 0;
  destinationLocationName = '';
  activated = false;
  constructor(option?: {
    id: string,
    destinationLocationName: string;
    routeRate: number;
    longitude: number;
    latitude: number;
    radius: number;
    activated?: boolean

  }) {

    if (option) {
      this.id = option.id;
      this.destinationLocationName = option.destinationLocationName;
      this.routeRate = option.routeRate;
      this.longitude = option.longitude;
      this.latitude = option.latitude;
      this.radius = option.radius;
      this.activated = option.activated;
    }

  }

}
