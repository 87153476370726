<div (window:resize)="onResize($event)" class="mi-grid-component">
  <!-- <div class="search-and-export-data-panel p-grid">
    <div class = "p-col-12 p-md-6 p-lg-6">
      <input *ngIf="!bottomSearchBar" type="text" class="grid-search-bar top" [(ngModel)]="search"
        (keyup)="onQuickFilterChanged($event, dt)" placeholder="Press enter to search" />
      <label for="search-bar" class="fa fa-search input-icon"></label>
    </div>
    <div class = "p-col-12 p-md-6 p-lg-6">
      <span class="export-button top" *ngIf="!bottomExportButton && (exportUrl !== '') && totalRecords > 0">
        <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download Excel">
          XLS
          <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
        </span>
      </span>
    
      <span class="export-button top" *ngIf="!bottomExportButton && (printUrl !== '') && totalRecords > 0">
        <span class="header-action-btn export" (click)="exportGridPdf($event)" title="Download Pdf">
          PDF
          <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
        </span>
      </span>
    </div>
  </div> -->
  
  <div class="search-and-export-data-panel p-d-flex p-flex-wrap">
    <div class = "p-col-12 p-md-6 p-lg-6">
      <input *ngIf="!bottomSearchBar" type="text" class="grid-search-bar top" [(ngModel)]="search"
        (keyup)="onQuickFilterChanged($event, dt)" placeholder="Press enter to search" />
      <label  *ngIf="!bottomSearchBar" for="search-bar" class="fa fa-search input-icon"></label>
    </div>
    <div class = "p-col-12 p-md-6 p-lg-6">
      <span class="export-button top" *ngIf="!bottomExportButton && (exportUrl !== '') && totalRecords > 0">
        <span class="header-action-btn export" (click)="exportGridExcel($event)" title="Download Excel">
          XLS
          <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
        </span>
      </span>
    
      <span class="export-button top" *ngIf="!bottomExportButton && (printUrl !== '') && totalRecords > 0">
        <span class="header-action-btn export" (click)="exportGridPdf($event)" title="Download Pdf">
          PDF
          <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
        </span>
      </span>
    </div>
  </div>
  <div class="p-col-12">
    <p-table #dt [ngClass]="isDetailScreen ? 'detail-screen-available' : ''" [lazy]="true" [rows]="paginationPageSize"
      metaKeySelection="false" [value]="datasource" [paginator]="pagination" [totalRecords]="totalRecords"
      (onLazyLoad)="loadDatasourceLazy($event)" [selectionMode]="rowSelection" (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectedRecords" [resizableColumns]="enableResizer"
      [first]="first" [responsive]="true"  styleClass="tablet-width">

      <!-- for tablet heading -->
      <!-- <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let col of columnDefs" [ngStyle] = "{'width': col.colWidth? col.colWidth:'130px'}"> 
        </colgroup>
        <colgroup *ngIf="actionButtonsDefs.length">
          <col  style="width:120px">
      </colgroup>
    </ng-template> -->
   
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columnDefs">

            <th *ngIf="col.resize && enableResizer" pResizableColumn [pSortableColumn]="(col?.sort) ? col.field: ''"
              [width]="col?.width" title="{{col?.header}}">
              <p-sortIcon *ngIf="col?.sort" [field]="col.field"></p-sortIcon>
              {{col.header}}
            </th>

            <th *ngIf="!col.resize" [pSortableColumn]="(col?.sort) ? col.field: ''" title="{{col.header}}"
              [width]="col?.width">
              <p-sortIcon *ngIf="col?.sort" [field]="col.field"></p-sortIcon>
              {{col.header}}
            </th>
          </ng-container>

          <th style="text-align: center;" *ngIf="actionButtonsDefs.length">
            Action
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td *ngIf="actionButtonsDefs.length === 0" style="text-align: center;" [attr.colspan]="columnDefs.length">
            No records found
          </td>
          <td *ngIf="actionButtonsDefs.length > 0" style="text-align: center;" [attr.colspan]="columnDefs.length + 1">
            No records found
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columnDefs" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [class]="rowIndex%2 === 0?'even-row':'odd-row'">
          <td [ngClass]="col?.class ? col.class : ''" *ngFor="let col of columnDefs">
            <span class="p-column-title">{{col.header}}</span>    
            <span style="word-wrap: inherit;" *ngIf="col.cellRendererHtml === undefined" [title]="rowData[col.field]">{{rowData[col.field]}}</span>
            <span *ngIf="col.cellRendererHtml !== undefined" [innerHtml]="rowData[col.field].innerHTML | keepHtml">
            </span>
          </td>

          <!-- <td [ngClass]="col?.class ? col.class : ''" *ngIf="actionButtonsDefs.length">
            <span *ngFor="let btn of actionButtonsDefs">
              <button *ngIf="rowData[btn.field] !== 'deactivated'" (click)="actionBtnClick(rowData, btn.class)" [icon]="btn.icon"
                [title]="btn?.label" pButton appRipple [label]="btn?.heading" pButton appRipple [class]="btn.class"></button>
            </span>
          </td> -->
          <td class="grid-action-buttons" *ngIf="actionButtonsDefs.length">
            <span class="m-r-15" *ngFor="let btn of actionButtonsDefs">
              <a *ngIf="btn.class === 'DETAILS-LINK'" [routerLink]="[pageDetailsLink]" [queryParams]="{id: rowData.id}">
               <span class="grid-btn view fa fa-fw fa-eye"></span>
             </a>
             <a *ngIf="btn.class === 'EDIT-LINK'" [routerLink]="[pageEditLink]" [queryParams]="{id: rowData.id}">
               <span class="grid-btn view fa fa-fw fa-pencil"></span>
             </a>
             <a *ngIf="btn.class === 'REPORT-DETAILS-LINK'" [routerLink]="[pageReportDetailsLink]" [queryParams]="{id: rowData.id}">
               <span class="grid-btn fa fa-hand-pointer-o  activate rightSpace"></span>
             </a>
             <a *ngIf="btn.class === 'EMERGENCY-DRIVER-DETAILS-LINK'" [routerLink]="[pageDetailsLink]" [queryParams]="{id: rowData.id, redirectTo: 'driverEmergency'}">
              <span class="grid-btn view fa fa-fw fa-eye"></span>
            </a>
            <a *ngIf="btn.class === 'EMERGENCY-PASSENGER-DETAILS-LINK'" [routerLink]="[pageDetailsLink]" [queryParams]="{id: rowData.id, redirectTo: 'passengerEmergency'}">
              <span class="grid-btn view fa fa-fw fa-eye"></span>
            </a>
            <a *ngIf="btn.class === 'PASSENEGER-DETAILS-LINK'" [routerLink]="[pageDetailsLink]" [queryParams]="{id: rowData.id, passengerId:passengerId}">
              <span class="grid-btn view fa fa-fw fa-eye"></span>
            </a>
             <span class="render-html" innerHTML="{{rowData[btn.field]}}"
               (click)="actionBtnClick($event, rowData, btn.class)"></span>
           </span>
          </td>
          <!-- </td> -->
        </tr>
      </ng-template>
    </p-table>
    <span class="grid-total-count ng-star-inserted">Total: {{totalRecords}}</span>
  </div>
</div>