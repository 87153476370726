<div class="ui-g form-container">
  <form [formGroup]="rentalPricingForm">
    <div class="ui-g">
      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["general"]'>
          <div *ngFor="let ErrorMessage of settingApiError['general']">
            {{ErrorMessage.message}}
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g">
      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label ui-g">
          <label for="category" translate>label_category_type</label>
          <label class="asterisk">*</label>
        </div>
        <div class="form-control">
          <input type="text" [appDisableControl]='true' pInputText formControlName="vehicleTypeId" />
        </div>
        <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["vehicleTypeId"]'>
          <div *ngFor="let ErrorMessage of settingApiError['vehicleTypeId']">
            {{ErrorMessage.message}}
          </div>
        </div>
        <div class="form-errors" *ngIf="rentalPricingForm.controls.vehicleTypeId.errors  && (rentalPricingForm.controls.vehicleTypeId.dirty || rentalPricingForm.controls.vehicleTypeId.touched)">
          <div [hidden]="!rentalPricingForm.controls.vehicleTypeId.errors.required" translate>
            category
          </div>
        </div>
      </div>

      <div class="form-field ui-g ui-md-12 ui-lg-12 ui-g-nopad rental" formArrayName="pricingBundle">
        <div class="form-field ui-g div-scroll ui-g-nopad">
          <div class="ui-g-12 ui-md-12 ui-lg-12 destination-box ui-g-nopad" [formGroupName]="i" *ngFor="let pricingBundle of rentalPricingForm.get('pricingBundle')['controls']; let i=index">
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6" [hidden]="true">
              <div class="form-label ui-g">
                <label for="pricingId" translate>label_package_name</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="pricingId" />
              </div>
            </div>
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label ui-g">
                <label for="packageName" translate>label_package_name</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="packageName" (focus)="settingApiError[0]['packageName']=false" />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["packageName"]'>
                <div *ngFor="let ErrorMessage of settingApiError['packageName']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.packageName.errors  
                 && (rentalPricingForm.controls.pricingBundle.controls[i].controls.packageName.dirty ||
                   rentalPricingForm.controls.pricingBundle.controls[i].controls.packageName.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.packageName.errors.required" translate>
                  package
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.packageName.errors.pattern" translate>
                  invalid_package
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label ui-g">
                <label for="distance" translate>label_distance_unit</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="distance" (focus)='settingApiError[0]["distance"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["distance"]'>
                <div *ngFor="let ErrorMessage of settingApiError['distance']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.distance.errors  
              && (rentalPricingForm.controls.pricingBundle.controls[i].controls.distance.dirty ||
               rentalPricingForm.controls.pricingBundle.controls[i].controls.distance.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.distance.errors.required" translate>
                  distance
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.distance.errors.pattern" translate>
                  distance_amount
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label ui-g">
                <label for="mins" translate>mins</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="minutes" (focus)='settingApiError[0]["baseRate"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["baseRate"]'>
                <div *ngFor="let ErrorMessage of settingApiError['baseRate']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.minutes.errors  && (rentalPricingForm.controls.pricingBundle.controls[i].controls.minutes.dirty || rentalPricingForm.controls.pricingBundle.controls[i].controls.minutes.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.minutes.errors.required" translate>
                  base_fare
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.minutes.errors.pattern" translate>
                  invalid
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label ui-g">
                <label for="addKm" translate>label_additional_KM</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="addKm" (focus)='settingApiError[0]["additionalDistanceRate"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["additionalDistanceRate"]'>
                <div *ngFor="let ErrorMessage of settingApiError['additionalDistanceRate']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.addKm.errors  && 
              (rentalPricingForm.controls.pricingBundle.controls[i].controls.addKm.dirty ||
               rentalPricingForm.controls.pricingBundle.controls[i].controls.addKm.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.addKm.errors.required" translate> addKm
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.addKm.errors.pattern" translate>
                  invalid
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label ui-g">
                <label for="addHr" translate>label_additional_hours</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="addHr" (focus)='settingApiError[0]["additionalMinuteRate"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["additionalMinuteRate"]'>
                <div *ngFor="let ErrorMessage of settingApiError['additionalMinuteRate']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.addHr.errors  
               && (rentalPricingForm.controls.pricingBundle.controls[i].controls.addHr.dirty ||
                 rentalPricingForm.controls.pricingBundle.controls[i].controls.addHr.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.addHr.errors.required" translate>
                  hours
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.addHr.errors.pattern" translate>
                  invalid_hours
                </div>
              </div>
            </div>

            <div class="form-field ui-g-4 ui-md-4 ui-lg-4">
              <div class="form-label ui-g">
                <label for="cancelFee" translate>label_cancellation_fee</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g">
                <input type="text" pInputText formControlName="cancelFee" (focus)='settingApiError[0]["cancellationRate"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!settingApiError[0]["cancellationRate"]'>
                <div *ngFor="let ErrorMessage of settingApiError['cancellationRate']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="rentalPricingForm.controls.pricingBundle.controls[i].controls.cancelFee.errors  && (rentalPricingForm.controls.pricingBundle.controls[i].controls.cancelFee.dirty || rentalPricingForm.controls.pricingBundle.controls[i].controls.cancelFee.touched)">
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.cancelFee.errors.required" translate>
                  cancellation
                </div>
                <div [hidden]="!rentalPricingForm.controls.pricingBundle.controls[i].controls.cancelFee.errors.pattern" translate>
                  invalid
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="form-buttons ui-g-12 ui-g-nopad">
        <button class="negative-button" pButton type="button" icon="fa-close" [label]="labelCancel" (click)="cancel()"></button>
        <button pButton type="button" [label]="labelSave" icon="fa-check" [disabled]="!rentalPricingForm.valid" (click)="addPackages()"></button>
      </div>
    </div>
    <p-toast key="message"></p-toast>
  </form>
</div>