import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { Message } from 'primeng/api';
import { ApprovalService } from './../../../corporate-pending-for-approval/services/approval.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';


@Component({
  selector: 'app-employee-trip-details',
  templateUrl: './employee-trip-details.component.html',
  providers: [ApprovalService]
})
export class EmployeeTripDetailsComponent implements OnInit, OnChanges {
  @Input() employeeTripDetails;
  @Input() bookingApprovalStatus;
  @Input() bookingId;
  employeeTripLength = 0;
  labelViewDetails;
  labelReject;
  labelApprove;
  display = false;
  message: Message[] = [];
  corpId;
  constructor(
    private commonBindingDataService: CommonBindingDataService,
    private router: Router,
    private approvalService: ApprovalService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.corpId = this.storageService.getItem(AppSettings.CURRENT_USER_CORPORATE_ID);
    this.labelReject = this.commonBindingDataService.getLabel('label_reject');
    this.labelApprove = this.commonBindingDataService.getLabel('label_approve');
    this.labelViewDetails = this.commonBindingDataService.getLabel('label_view_details');
  }
  ngOnChanges() {
    if (this.employeeTripDetails !== null && this.employeeTripDetails !== undefined) {
      this.employeeTripLength = this.employeeTripDetails.length;
    }
  }

  onViewDetails(item) {
    this.router.navigate(['corporate-employee/details/booking-details'], { queryParams: { id: item.id } });
  }

  onReject(data) {
  }

  onApprove(status) {
    this.approvalService.handleApprove(this.corpId, this.bookingId, status).subscribe(results => {
      results.general.forEach(element => {
        this.message = [];
        this.message.push({ severity: 'success', summary: 'Success', detail: element.message });
        this.router.navigate(['pending-for-approval']);
      });
    }, (error) => {
      error.general.forEach(element => {
        this.message = [];
        this.message.push({ severity: 'error', summary: 'Error', detail: element.message });
      });
    });
  }

  showDialog() {
    this.display = true;
  }


}
