import * as moment from 'moment';

export function formatDateCell(timestamp) {
  return moment(timestamp).format('MMM DD, YYYY hh:mm A');
}

export function formatDateCellInDateOnly(timestamp) {
  return moment(timestamp).format('MMM DD, YYYY');
}

export function toLocalDateAndtime(timeStamp) {
  const now = new Date(timeStamp);
  const localTime = new Date(timeStamp + now.getTimezoneOffset() * 60000);
  return moment(localTime).format('MMM DD, YYYY hh:mm A');
}
