<div class="track-my-location-container">
  <div class="track-my-location">

    <div class="header p-col-12 p-md-12 p-lg-12 p-sm-12">
      <div class="p-col-6 p-md-12 p-lg-6 p-sm-12 contact-info">
        <a href="" target="_blank">
          <img width="200" src="/assets/images/temp/MI_black_logo.png">
        </a>
      </div>
    </div>
    <div class=" p-col-nopad p-col-12 p-md-12 p-lg-12">
      <div class="seperator"></div>
    </div>

    <div class="p-g-12 p-md-12 p-lg-12">
      <h1 [innerHTML]="privacyPolicyData"></h1>
    </div>
  </div>
</div>