import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../../shared/app.settings';
import { InfoModel } from './../../../passengers/models/info-model';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-booking-user-details',
  templateUrl: './booking-user-details.component.html'
})

export class BookingUserDetailsComponent implements OnChanges {
  @Input() passengerDetails = null;
  @Input() bookingsCount;
  country = '';
  city = '';
  Rating;
  isIdologyDriverVerification = this.commonBindingDataService.getTenantConfigValue(AppSettings.IS_DRIVER_IDOLOGY_VERIFICATION);

  profileImageUrl = AppSettings.GET_FILE_THUMB_URL;
  imageUrl = null;
  constructor(private commonBindingDataService: CommonBindingDataService) { }

  ngOnChanges(samplechange: SimpleChanges) {
    if (samplechange.passengerDetails.currentValue !== null) {
      this.passengerDetails = new InfoModel(this.passengerDetails);
      if (this.passengerDetails.profileImage !== undefined
        && this.passengerDetails.profileImage !== null
        && this.passengerDetails.profileImage !== '') {
        this.imageUrl = this.profileImageUrl + this.passengerDetails.profileImage;
      } else {
        this.imageUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
      if (this.passengerDetails.passengerRating !== undefined
        && this.passengerDetails.passengerRating !== null
        && this.passengerDetails.passengerRating !== '') {
        this.Rating = this.passengerDetails.passengerRating;
        this.passengerDetails.passengerRating = '';
      }
      if (this.passengerDetails !== null && this.passengerDetails !== undefined) {
        this.commonBindingDataService.getCountries().subscribe(results => {
          results.forEach(element => {
            if (element.countryCode === this.passengerDetails.countryCode) {
              this.country = element.countryName;
            }
          });

        }, (error) => {
          // this.editDriverForm.controls.serviceType.setErrors(error.general[0].message);
        });
      }
    }

  }

}
