import { Component, OnInit } from '@angular/core';
import { AppSettings } from './modules/shared/app.settings';
import { Router } from '@angular/router';
import { UserCommonService } from './modules/shared/services/user-common.service';
import { NotificationsService } from './modules/shared/services/notifications.service';
import { MessagingService } from './service/messaging.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  showHeaderAndSidebar = false;
  message: any;
  token: any;
  constructor(
    private router: Router,
    public notificationService: NotificationsService,
    public userCommonService: UserCommonService,
    private messagingService: MessagingService
  ) {
    this.router.events.subscribe((currentPath: any) => {
      if (currentPath.url !== undefined && currentPath.url !== null) {
        const browserUrl = currentPath.url.split('?');
        if (AppSettings.HEADER_NOT_REQUIRED.includes(browserUrl[0])) {
          this.showHeaderAndSidebar = false;
        } else {
          this.showHeaderAndSidebar = true;
        }
      }
    });
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    this.token = this.messagingService.browsertoken;
  }
}
