import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonBindingDataService } from './../shared/services/common-binding-data.service';
import { ErrorBindingService } from '../shared/services/error-binding.service';
import { Message, ConfirmationService } from 'primeng/api';
import { AppSettings } from '../shared/app.settings';
import { UserCommonService } from '../shared/services/user-common.service';

@Component({
  selector: 'app-payment-strip',
  templateUrl: './payment-strip.component.html'
})
export class PaymentStripComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  payment: any = true;
  userId = '';
  campaignId = '';
  mediaType = '';
  bookingData: any = {};
  paymentProcessing = false;
  pmId;
  paymentFailed = false;
  message: Message[] = [];
  manualBookingErrors = [{
    estimateId: false,
    vehicleTypeId: false,
    serviceTypeId: false,
    pickupLocationName: false,
    sourceLatitude: false,
    sourceLongitude: false,
    dropLocationName: false,
    destinationLatitude: false,
    destinationLongitude: false,
    promoCode: false,
    rideType: false,
    paymentType: false,
    passengerType: false,
    driverType: false,
    pickupDateTime: false,
    rideLaterPickupTime: false
  }];
  serviceTypeId = AppSettings.TAXI;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private commonService: CommonBindingDataService,
    private errorBindingService: ErrorBindingService,
    private userCommonService: UserCommonService


  ) { }

  ngOnInit() {
    // if (this.commonService.donor) {
    //   this.payment = this.commonService.donor;
    // }


    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.campaignId = this.route.snapshot.params['c'];
    this.mediaType = this.route.snapshot.queryParamMap.get('mediaType');
    if (this.commonService.manualBookingData) {
      this.bookingData = this.commonService.manualBookingData;
    } else {
      this.router.navigate(['bookings']);
    }

  }

  ngAfterViewInit() {

    this.card = this.userCommonService.stripElements.create('card', {
      hidePostalCode: true
    });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);


    // if (this.commonService.donor) {
    //   this.payment = this.commonService.donor;
    // } else {
    // this.router.navigate(['/donation/' + this.campaignId], {
    //   queryParams: {
    //     userId: this.userId,
    //     mediaType: this.mediaType
    //   }
    // });
    // }
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    this.paymentProcessing = true;
    const { token, error } = await this.userCommonService.stripe.createToken(this.card);

    if (error) {
      this.paymentProcessing = false;
      console.log('Something is wrong:', error);
    } else {
      this.stripeUpdate(token);
      // this.makePaymentCall(token);
    }
  }

  stripeUpdate(stripeTokenObj) {
    let pMId;
    const self = this;
    this.userCommonService.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.bookingData.pFirstName + ' ' + this.bookingData.pLastName,
        },
      })
      .then(function(result) {
        if (result.paymentMethod) {
          pMId = result.paymentMethod.id;
          self.makePaymentCall(stripeTokenObj, pMId);

        } else {
          console.log('Something is wrong:', result.error);
        }

        // Handle result.error or result.paymentMethod
      });
    // if(pMId) {
    //   debugger
    //   this.pmId = pMId;
    //   this.makePaymentCall(stripeTokenObj,pMId);
    // }
  }


  async makePaymentCall(stripeTokenObj, pMId) {

    const payload = {
      // refereeUserId: this.route.snapshot.queryParamMap.get('userId'),
      // campaignId: this.campaignId,
      // mediaType: this.mediaType,
      // amount: this.payment.amount,
      // donorName: this.payment.name,
      // donorMessage: this.payment.message,
      // donorEmail: this.payment.email,
      passengerId: this.bookingData.passengerId,
      email: this.bookingData.pEmail,
      numCountryCode: this.bookingData.pNumCountryCode,
      phoneNum: this.bookingData.pPhoneNum,
      cardId: stripeTokenObj.card.id,
      cardType: stripeTokenObj.card.brand,
      cardNumber: 'xxxx xxxx xxxx ' + stripeTokenObj.card.last4,
      // stripeToken: stripeTokenObj.id,
      stripeToken: pMId


    };

    this.commonService.doPayment(payload).subscribe(results => {
      this.bookingData.stripeCardId = results.id;
      if (results.id) {
        if (this.commonService.rideType !== '3') {

          this.commonService.bookRideNowAndRideLater(this.serviceTypeId, this.bookingData).subscribe(result => {
            // this.router.navigate(['bookings'], { queryParams: { severity: 'Success', message: result.general[0].message } });
            this.showMessage('Success', result.general[0].message);
            setTimeout(() => {
              this.message = [];
              this.router.navigate(['bookings']);
            }, 2000);
          }, (error) => {
            this.showMessage('Error', error.general[0].message);
            this.errorBindingService.onAPIValidationError(error, this.manualBookingErrors);
          });

        } else {
          this.commonService.bookRideShare(this.bookingData).subscribe(result => {
            // this.redirectUrl(result.general[0].message);
            this.router.navigate(['bookings'], { queryParams: { message: result.general[0].message } });

          }, (error) => {
            this.showMessage('Error', error.general[0].message);
            this.errorBindingService.onAPIValidationError(error, this.manualBookingErrors);
          });
        }
      }
    }, (error) => {
      this.paymentProcessing = false;
      this.paymentFailed = true;

    });
  }

  showMessage(severity, message) {
    this.message = [];
    this.message.push({ severity: severity.toLowerCase(), summary: severity, detail: message });
  }

}
