import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorize-success-page',
  templateUrl: './authorize-success-page.component.html',
  styleUrls: ['./authorize-success-page.component.scss']
})
export class AuthorizeSuccessPageComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }

}
