<div class="ui-g ui-g-nopad">
  <div class="ui-lg-12 ui-g-nopad">
    <form [formGroup]='editEmployee'>
      <div class="ui-g">
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["general"]'>
            <div *ngFor="let ErrorMessage of empAPIError['general']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
      </div>
      <div class="ui-g-12 ui-g-nopad corp-user-dialog">
        <div class="ui-g-2">
            <img  style="width:100%" [src]="profileUrl" onerror="src='/assets/images/temp/default_profile.png'" />
            <div class="display-image">
              <app-file-uploader (fileUpload)="onUploadProfile($event)" accept="image/*" [uploadButtonLabel]="chooseLabel"></app-file-uploader>
            </div>
        </div>
        <div class="ui-g-10">
          <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="empId" translate>lable_employeeID</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control">
                <input type="text" pInputText formControlName="empId" (focus)='empAPIError[0]["empId"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["empId"]'>
                <div *ngFor="let ErrorMessage of empAPIError['empId']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="editEmployee.controls.empId.errors  && (editEmployee.controls.empId.dirty || editEmployee.controls.empId.touched)">
                <div [hidden]="!editEmployee.controls.empId.errors.required" translate>
                  empId_req
                </div>
                <div [hidden]="!editEmployee.controls.empId.errors.pattern" translate>
                  empId_pattern
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="category" translate>label_department</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control">
                <p-dropdown [options]="deptOptions" formControlName="department" (onFocus)="empAPIError[0]['departmentId']=false">
                </p-dropdown>
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["department"]'>
                <div *ngFor="let ErrorMessage of empAPIError['department']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="editEmployee.controls.department.errors  && (editEmployee.controls.department.dirty || editEmployee.controls.department.touched)">
                <div [hidden]="!editEmployee.controls.department.errors.required" translate>
                  department_req
                </div>
              </div>
            </div>

          </div>
          
          <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label for="name" translate> label_name</label>
                <label class="asterisk">*</label>
              </div>
              <div class="ui-g-12 ui-g-nopad">
                <input type="text" pInputText formControlName="name" (focus)='empAPIError[0]["name"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["name"]'>
                <div *ngFor="let ErrorMessage of empAPIError['name']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="editEmployee.controls.name.errors  && (editEmployee.controls.name.dirty || editEmployee.controls.name.touched)">
                <div [hidden]="!editEmployee.controls.name.errors.required" translate>
                  name_req
                </div>
                <div [hidden]="!editEmployee.controls.name.errors.pattern" translate>
                  name_pattern
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
            
              <div class="form-label  ui-g-12 lable-mobile-number">
                <label translate>phone</label>
                <label class="asterisk">*</label>
              </div>

                <div class="ui-g-3  ui-inputgroup mobile-number">
                  <span class="ui-inputgroup-addon">
                    <i class="fa fa-plus"></i>
                  </span>
                  <input class="input-mobile-code" type="text" pInputText formControlName="countryCode" placeholder="Code" />
                </div>

                <div class="ui-g-9 ui-g-nopad employee-input">
                  <input type="text" pInputText formControlName="contactNo" (focus)='empAPIError[0]["contactNo"]=false' placeholder="phone number"
                  />
                </div>

              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["contactNo"]'>
                <div *ngFor="let ErrorMessage of empAPIError['contactNo']">
                  {{ErrorMessage.message}}
                </div>
              </div>

              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["numCountryCode"]'>
                <div *ngFor="let ErrorMessage of empAPIError['numCountryCode']">
                  {{ErrorMessage.message}}
                </div>
              </div>

              <div class="form-errors" *ngIf="editEmployee.controls.contactNo.errors  && (editEmployee.controls.contactNo.dirty || editEmployee.controls.contactNo.touched)">
                <div [hidden]="!editEmployee.controls.contactNo.errors.required" translate>
                  phone_required
                </div>
                <div [hidden]="!editEmployee.controls.contactNo.errors.minlength" translate>
                  phone_invalid
                </div>
                <div [hidden]="!editEmployee.controls.contactNo.errors.maxlength" translate>
                  phone_invalid
                </div>
                <div [hidden]="!editEmployee.controls.contactNo.errors.pattern" translate>
                  number_pattern
                </div>
              </div>

              <div class="form-errors" *ngIf="editEmployee.controls.countryCode.errors  && (editEmployee.controls.countryCode.dirty || editEmployee.controls.countryCode.touched)">
                <div [hidden]="!editEmployee.controls.countryCode.errors.required" translate>
                  country_code_required
                </div>
                <div [hidden]="!editEmployee.controls.countryCode.errors.pattern" translate>
                   country_code_invalid
                </div>
                <div [hidden]="!editEmployee.controls.countryCode.errors.maxlength" translate>
                   country_code_invalid
                </div>
                <div [hidden]="!editEmployee.controls.countryCode.errors.minlength" translate>
                  country_code_invalid
                </div>
              </div>        
            </div>
          </div>

          <div class="ui-g">
            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-label">
                <label translate>email</label>
                <label class="asterisk">*</label>
              </div>
              <div class="form-control">
                <input type="text" pInputText formControlName="email" (focus)='empAPIError[0]["emailId"]=false' />
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["emailId"]'>
                <div *ngFor="let ErrorMessage of empAPIError['emailId']">
                  {{ErrorMessage.message}}
                </div>
              </div>
              <div class="form-errors" *ngIf="editEmployee.controls.email.errors  && (editEmployee.controls.email.dirty || editEmployee.controls.email.touched)">
                <div [hidden]="!editEmployee.controls.email.errors.required" translate>
                  email_required
                </div>
                <div [hidden]="!editEmployee.controls.email.errors.pattern" translate>
                  email_pattern
                </div>
              </div>
            </div>

            <div class="form-field ui-g-12 ui-md-6 ui-lg-6">
              <div class="form-control" style="padding-top: 6%">
                <p-checkbox name="VPassenger" binary="true" label="VIP Passenger" formControlName="VIPPassenger"></p-checkbox>
              </div>
              <div class="form-errors alert alert-danger" [hidden]='!empAPIError[0]["vip"]'>
                <div *ngFor="let ErrorMessage of empAPIError['vip']">
                  {{ErrorMessage.message}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-buttons ui-g-12 ui-g-nopad">
          <button pButton type="button" [label]="labelCancel" (click)="cancel()"></button>
          <button pButton type="button" [label]="labelSave" [disabled]="!editEmployee.valid" (click)="addEmployeeDetails()"></button>
        </div>
      </div>
    </form>
  </div>
  <p-toast key="message"></p-toast>