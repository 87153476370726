import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'minutsformat'
})
export class MinutsFormatPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let format = 'hh:mm A';
    if (args !== undefined) {
      format = args;
    }
    if (value > 0) {
      return moment(value).format('hh:mm A');
    } else {
      return '--:--';
    }

    // return moment.utc().startOf('day').add(value, 'minutes').format(format);
  }

}
