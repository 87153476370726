import { Injectable } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';

// import 'rxjs/add/operator';
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  browsertoken = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);

      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.browsertoken.next(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }


  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        // console.log("priority. ", JSON.parse(payload));
        const converted: any = {} = JSON.stringify(payload);
        const converted1: any = {} = JSON.parse(converted);
        console.log('concvreted', converted1.notification.body);
        this.currentMessage.next(payload);
        if (!('Notification' in window)) {
          console.log('This browser does not support desktop notification');
        } else {
          console.log('supports');
        }


        if (Notification.permission === 'granted') {
          // If it's okay let's create a notification
          // var notification = new Notification('while open',{body:converted.notification.body,icon:'https://picsum.photos/200'});
          const notification = new Notification(converted1.notification.title, {
            body: converted1.notification.body,
            icon: converted1.notification.icon
          });
          console.log('granted');
        } else {
          console.log('not granted');
        }

      })
  }

}
