import { EmployeeUserDetailsModel } from './employee-user-details';
export class EmployeeModel {

  email = '';
  phoneNum = '';
  firstName = '';
  lastName = '';
  numCountryCode = '';
  userDetails: EmployeeUserDetailsModel;
  constructor(option?: {
    email: string
    phoneNum: string
    firstName: string
    lastName: string
    numCountryCode: string
    userDetails: EmployeeUserDetailsModel
  }) {

    if (option) {
      this.email = option.email;
      this.phoneNum = option.phoneNum;
      this.firstName = option.firstName;
      this.lastName = option.lastName;
      this.userDetails = option.userDetails;
      this.numCountryCode = option.numCountryCode;
    }
  }
}
