<p-dialog header="Upload Quotation" [(visible)]="display" modal="modal" [style]="{'width': '300px'}" [responsive]="true" [closable]="false">

  <form [formGroup]="uploadQuotationForm" class="ui-g-12">
    <div class="ui-g">
      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label">
          <label for="quotationamount" translate>label_quoation_amount</label>
        </div>
        <div class="form-control">
          <input type="text" pInputText formControlName="quotationAmount" placeholder="amount" />
          <div class="form-errors" *ngIf="uploadQuotationForm.controls.quotationAmount.errors  && (uploadQuotationForm.controls.quotationAmount.dirty || uploadQuotationForm.controls.quotationAmount.touched)">
            <div [hidden]="!uploadQuotationForm.controls.quotationAmount.errors.required" translate>
              error_quotation_amount
            </div>
            <div [hidden]="!uploadQuotationForm.controls.quotationAmount.errors.pattern" translate>
              invalid_amount
            </div>
          </div>
        </div>
      </div>

      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label">
          <label for="quotationnote" translate>label_quoation_note</label>
        </div>
        <div class="form-control">
          <textarea pInputTextarea formControlName="quotationNote" placeholder="add note"></textarea>

          <div class="form-errors" *ngIf="uploadQuotationForm.controls.quotationNote.errors  && (uploadQuotationForm.controls.quotationNote.dirty || uploadQuotationForm.controls.quotationNote.touched)">
            <div [hidden]="!uploadQuotationForm.controls.quotationNote.errors.required" translate>
              error_quotation_note
            </div>
          </div>
        </div>
      </div>
      <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
        <div class="form-label text-ellipsis mb-10">
          <label for="filename">{{uploadedQuotationFileName}}</label>
        </div>
        <app-file-uploader (fileUpload)="onUpload($event)" accept="application/pdf" [uploadButtonLabel]="labelUploadQuotePdf"></app-file-uploader>
        <div class="form-errors" *ngIf="uploadQuotaError.file" translate>
          error_quotatin_file
        </div>
      </div>

    </div>
  </form>
  <p-toast key="message"></p-toast>
  <p-footer>
    <button type="button" class="" [disabled]="doValidSave()" pButton icon="fa-check" (click)="onSave()" label="Save"></button>
    <button class="negative-button " pButton type="button" icon="fa-close" label="Cancel" (click)="onCancel()"></button>
  </p-footer>
</p-dialog>