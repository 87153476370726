<!-- <div [hidden]="!show">
    <div [class]="cssClass">
        <div [hidden]="!show">
            <i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div> -->

<div [hidden]="!show">
    <div class="progress-loader"></div>
</div>