import { Directive, ElementRef, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { CommonBindingDataService } from './../services/common-binding-data.service';
declare const google: any;
@Directive({
  selector: '[appAccessProvider]'
})
export class AccessProviderDirective implements OnChanges {
  @Input() accessCode;
  private _el: HTMLElement;
  private menuAccessList: any[];

  constructor(el: ElementRef, private commonBindingDataService: CommonBindingDataService) {
    this._el = el.nativeElement;
  }

  ngOnChanges() {
    this.menuAccessList = this.commonBindingDataService.getSelectedMenuAccessesList();
    const hasAccess = this.menuAccessList.find((elm) => {
      // return elm.id === this.accessCode;
      return elm.accessCode === this.accessCode;
    });
    if (!hasAccess) {
      this._el.style.display = 'none';
    }
  }

}
