import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

import { AppSettings } from './../../app.settings';
import { StorageService } from './../../services/storage.service';
import { CommonBindingDataService } from './../../../shared/services/common-binding-data.service';
import { formatDateCell } from './../../../shared/utility-functions/grid';

import { ViewChild, OnInit } from '@angular/core';
import { MiGrid2Component } from '../mi-grid-2/mi-grid-2.component';


@Component({
  selector: 'app-recent-emergency-request',
  templateUrl: './recent-emergency-request.component.html'
})
export class RecentEmergencyRequestComponent implements OnInit {
  @Input() headerLHS;
  gridTheme = 'mi';
  showSerialNo = false;
  url = '/secure/emergency/list/data';
  gridColumn: any;
  exportUrl = '';
  printUrl = '';
  params: any = '';
  corpId;

  labelSave;
  labelCancel;
  columns: any = [];
  @ViewChild(MiGrid2Component, { static: true }) gridRef2: MiGrid2Component;



  constructor(private commonService: CommonBindingDataService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.corpId = this.storageService.getItem(AppSettings.CURRENT_USER_CORPORATE_ID);
    this.columns.push({
      field: 'bookingCode', header: this.commonService.getLabel('label_booking_ID'),
      sort: true, resize: true
    });
    this.columns.push({
      field: 'driverName', header: this.commonService.getLabel('label_driver_name'),
      sort: true, resize: true
    });
    this.columns.push({
      field: 'locationName', header: this.commonService.getLabel('label_current_location'),
      sort: true, resize: true
    });
    this.columns.push({
      field: 'reason', header: this.commonService.getLabel('label_reason'),
      sort: true, resize: true
    });
    this.columns.push({
      field: 'createdAt', header: this.commonService.getLabel('label_date_time_list'),
      sort: true, resize: true, cellRenderer: (i) => (formatDateCell(i))
    });

  }

  ngOnInit() {

    this.params = '';
    if (this.corpId) {
      this.url = this.url + '?partnerType=Corporate&partnerId=' + this.corpId;
    } else {
      this.url = this.url + '?partnerType=&partnerId=';
    }

    // this.gridRef2.updateUrl(this.url);
  }


  viewAllNotifications() {
    this.router.navigate(['emergency-request']);
  }

}
