<div class="track-my-location-container">
    <div class="track-my-location">
  
      <div class="header p-col-12 p-md-12 p-lg-12 p-sm-12">
        <div class="p-col-6 p-md-12 p-lg-6 p-sm-12">
          <a href="" target="_blank">
            <img width="200" src="/assets/images/temp/MI_black_logo.png">
          </a>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-lg-12">
        <div class="seperator"></div>
      </div>
  <div align="center" class="form-container">
      <div class="white-patch">
      <form class="p-lg-12 p-md-12 p-sm-12" [formGroup]="authorizeForm" style="padding-top:99px;">
        <h2>Authorized Payment Gateway</h2>
            <div class="form-field p-md-4 p-lg-4 p-sm-12">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Card Number</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="form-control">
                  <input type="text" [placeholder]="" name="" id="tbNum" maxlength="19" formControlName="cardNumber" appFourDigitSpace/>
                </div>
                <div class="form-errors"
                  *ngIf="authorizeForm.controls.cardNumber.errors  && (authorizeForm.controls.cardNumber.dirty || authorizeForm.controls.cardNumber.touched)">
                  <div [hidden]="!authorizeForm.controls.cardNumber.errors.required" translate>
                    Card number is required.
                  </div>
                  <div [hidden]="!authorizeForm.controls.cardNumber.errors.pattern" translate>
                    Invalid Card Number. 
                </div>
                </div>
              </div>
            <div class="form-field p-md-4 p-lg-4 p-sm-12">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Expiration (MM/YY)</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="form-control">
                  <input type="text" [placeholder]="" name="" pInputText maxlength="5" formControlName="expiration" (keyup)="validateExpirationDate($event)" appExpiryDate/>
                </div>
                <div class="form-errors"
                  *ngIf="authorizeForm.controls.expiration.errors  && (authorizeForm.controls.expiration.dirty || authorizeForm.controls.expiration.touched)">
                  <div [hidden]="!authorizeForm.controls.expiration.errors.required" translate>
                    expiration is required.
                  </div>
                  <div [hidden]="!authorizeForm.controls.expiration.errors.pattern" translate>
                   Invalid expiration date.
                  </div>
                </div>
                <div class="form-errors" *ngIf="showExpirationMessage" translate>
                     Please enter valid expiration date.
                 </div>
            </div>
            <!-- <div class="form-field p-md-4 p-lg-4 p-sm-12">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Card Security Number</label>
                   <label class="asterisk">*</label> -->
                <!-- </div>
                <div class="form-control">
                  <input type="text" [placeholder]="" name="" pInputText formControlName="cardSecurityCode" />
                </div>
                <div class="form-errors"
                  *ngIf="authorizeForm.controls.cardSecurityCode.errors  && (authorizeForm.controls.cardSecurityCode.dirty || authorizeForm.controls.cardSecurityCode.touched)">
                  <div [hidden]="!authorizeForm.controls.cardSecurityCode.errors.required" translate>
                    cardSecurityCode is required.
                  </div>
                </div>
              </div>  -->
    </form>
    </div>
  </div>
    <div class="form-buttons p-md-8 p-lg-8">
        <button pButton type="button" label="Save Card" icon="fa fa-check" [disabled]="!authorizeForm.valid || showExpirationMessage"
          (click)="addNow()"></button>
      </div>
  
    </div>
  </div>
  <p-toast key="message"></p-toast>