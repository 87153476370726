<app-loader></app-loader>
<!-- {{ message | async | json }} -->
<!-- {{ token | async | json }} -->
<div *ngIf="userCommonService?.isMetadataLoaded" class="p-col-12 device-height web-fixed">
  <app-header *ngIf="notificationService.canFetchNotification" class="top-nav p-grid header-panel"></app-header>
  <div class="main-panel p-grid">

    <app-sidebar *ngIf="notificationService.canFetchNotification" class="sidebar left-panel sidebar-scroll">
    </app-sidebar>

    <div class="right-panel">
      <app-page-header *ngIf="notificationService.canFetchNotification"></app-page-header>

      <div class="web-right-pad right-panel-scroll">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<p-toast key="commonToast"></p-toast>