import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-activity',
  templateUrl: './dashboard-activity.component.html'
})
export class DashboardActivityComponent implements OnInit {

  @Input() headerLHS;
  @Input() headerRHS;
  @Input() activityNotifications;
  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }

}
