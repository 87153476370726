import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../../shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-driver-details',
  templateUrl: './booking-driver-details.component.html'
})
export class BookingDriverDetailsComponent implements OnChanges {

  @Input() driverDetails;
  @Input() vehicleDetails;
  @Input() bookingCode;
  @Output() trackDriverLocation: EventEmitter<any> = new EventEmitter();

  driverMapDetails;

  profileImageUrl: string = AppSettings.GET_FILE_THUMB_URL;
  imageUrl = null;
  rating = null;
  viewDriverLocationPopup = false;

  constructor(private router: Router,
    private commonBindingDataService: CommonBindingDataService) { }

  ngOnChanges() {
    if (this.driverDetails !== undefined && this.driverDetails !== null) {
      if (this.driverDetails.profileImage !== undefined
        && this.driverDetails.profileImage !== null
        && this.driverDetails.profileImage !== '') {

        this.imageUrl = this.profileImageUrl + this.driverDetails.profileImage;
      } else {
        this.imageUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
      }
      if (this.driverDetails.rating !== undefined
        && this.driverDetails.rating !== null
        && this.driverDetails.rating !== '') {
        this.rating = parseInt(this.driverDetails.rating, 10);
        if (this.rating < this.driverDetails.rating) {
          this.rating = this.rating + 1;
        }
        this.driverDetails.rating = this.rating;
      }
    }
  }

  trackDriverLocations(driverId) {
    this.trackDriverLocation.emit(driverId);
  }



}

