import { Component, OnInit } from '@angular/core';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicyData: any;

  constructor(
    private commonBindingDataService: CommonBindingDataService,
  ) { }

  ngOnInit() {
    this.commonBindingDataService.getPrivacyPolicy().subscribe((result) => {
      this.privacyPolicyData = result;
    }, (reason) => {
    });
  }
}
