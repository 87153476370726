import { StorageService } from './../../shared/services/storage.service';
import { Injectable } from '@angular/core';
import { RestApiService } from '../../shared/services/rest-api.service';
import { AppSettings } from '../../shared/app.settings';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';

@Injectable()
export class BookingService {
  bookingDetail: any = null;
  obj: any;
  serviceTypeOptions: any[] = [];
  selectedService: any = '';
  statusOptions: any[] = [];
  selectedStatus: any = '';
  params: any = '';

  packageApiError = [{
    driverId: false,
    serviceTypeId: false,
    partnerId: false,
    partnerType: false,
    phoneNum: false,
    passengerName: false,
    vehicleTypeId: false,
    vehicleId: false,
    packageId: false,
    bookingStatus: false,
    sourceLattitude: false,
    sourceLongitude: false,
    destinationLatitude: false,
    destinationLongitude: false,
    pickupLocationName: false,
    dropLocationName: false,
    pickupDateTime: false,
    passangerCount: false,
    vip: false,
    vehicleAutomatic: false,
    withFuel: false,
    note: false,
    general: false,
    makeModel: false,
  }];

  constructor(
    private restApiService: RestApiService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  addUpdateBooking(data, serviceType, id?): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}`, data);
  }

  addChauffeurBooking(data, serviceType): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/chauffeur`, data);
  }


  addUpdateRentalBooking(data, serviceType): Observable<any> {
    return this.restApiService.post(`/secure/booking/${serviceType}/rental`, data);
  }

  packageBind(serviceType, passengerId?, vehicleTypeId?): Observable<any> {
    if (vehicleTypeId !== null && vehicleTypeId !== undefined) {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType +
        '?vehicleTypeId=' + vehicleTypeId + '&passengerId' + passengerId;
      return this.restApiService.get(url);
    } else {
      const url = '/secure/packages/allpackages/'
        + this.storageService.getItem(AppSettings.SERVICE_AREA_ID) + '/' + serviceType;
      return this.restApiService.get(url);
    }
  }

  searchDriver(url: string): Observable<any> {
    return this.restApiService.get(url);
  }

  searchPassengers(url: string): Observable<any> {
    return this.restApiService.get(url);
  }

  assignDriver(bookingId, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/assign-driver', data);
  }
  cancelBooking(id, data): Observable<any> {
    return this.restApiService.put('/secure/booking/' + id + '/cancel', data);
  }

  bookingDetails(id): Observable<any> {
    return this.restApiService.get('/secure/booking/' + id);
  }


  fetchTourBooking(tourId): Observable<any> {
    return this.restApiService.get('/secure/tour/' + tourId);
  }

  getPromocodeList(userId): Observable<any> {
    return this.restApiService.get('/secure/promo-code/' + userId + '/list?serviceTypeId=&serviceAreaId=');
  }

  
  getRidePreferences(vehicleId): Observable<any> {
    return this.restApiService.get(`/secure/ride-preferences/vehicle-type-preferences/${vehicleId}`);
  }

  startTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/start-trip', data);
  }

  fetchTourRouteLatLngBooking(tourId): Observable<any> {
    return this.restApiService.get('/secure/tour/actual-path/' + tourId);
  }

  endTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/end-trip', data);
  }

  cancelTrip(data, tripid): Observable<any> {
    return this.restApiService.put('/secure/booking/trip/' + tripid + '/cancel-trip', data);
  }

  resendCode(bookingId, passengerId): Observable<any> {
    return this.restApiService.put('/secure/booking/' + bookingId + '/verification-code');
  }

  getCities(): Observable<any> {
    return this.restApiService.get('/secure/service-area/cities');
  }

  getRegionByCityId(cityId): Observable<any> {
    return this.restApiService.get(`/secure/service-area/regions/${cityId}`);
  }

  sendEmailInvoice(tourId, data): Observable<any> {
    return this.restApiService.post(`/secure/tour-receipt/email/` + tourId, data, 'page-center');
  }

  refundBookingAmount(tourId, data): Observable<any> {
    return this.restApiService.post(`/secure/tour/refund/` + tourId, data, 'page-center');
  }

  /*Manual booking API */
  getPassengersList(searchString?): Observable<any> {
    return this.restApiService.get('/secure/admin-booking/passenger-list?searchString=' + searchString);
  }

  getDriversList(srcLat, srcLng, destLat, DestLng, numOfSeats, rideTypeId, vehicleTypeId,
    searchString?, pickUpDateRideLater?, serviceTypeId?, passengerId?): Observable<any> {
    return this.restApiService.get('/secure/admin-booking/driver-list?rideTypeId=' + rideTypeId + '&pickupLat=' + srcLat +
      '&pickupLong=' + srcLng + '&destLat=' + destLat + '&destLong=' + DestLng + '&numOfSeats=' +
      numOfSeats + '&vehicleTypeId=' + vehicleTypeId + '&searchString=' + searchString + '&pickupDateTime='
      + pickUpDateRideLater + '&limit=9999&offset=0' + '&serviceTypeId=' + serviceTypeId);
  }

  getPreBookingDetails(): Observable<any> {
    return this.restApiService.get(`/secure/admin-booking/pre-booking/data`, 'page-center');
  }

  getFareEstimate(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/booking/${servicetypeid}/fare-estimation`, data, 'page-center');
  }

  getFareEstimateForRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/fare-estimate`, data, 'page-center');
  }


  bookRideNowAndRideLater(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/booking/manual/${servicetypeid}`, data, 'page-center');
  }

  bookRideNowAndRideLaterRental(data): Observable<any> {
    return this.restApiService.post(`/secure/rental/booking/ADMIN`, data, 'page-center');
  }

  bookRideNowAndRideLaterOccasional(servicetypeid, data): Observable<any> {
    return this.restApiService.post(`/secure/occasional-ride-booking/manual/${servicetypeid}`, data, 'page-center');
  }

  bookRideShare(data): Observable<any> {
    return this.restApiService.post(`/secure/ride-share/booking/manual`,
      data, 'page-center');
  }

  getNearByVehicles(sLatitude, sLongitude, dLatitude, dLongitude, radius, serviceTypeId, vehicleTypeId): Observable<any> {
    return this.restApiService.get(`/secure/vehicles/data/nearbyvehicles/eta?sLatitude=${sLatitude}&sLongitude=${sLongitude}` +
      `&dLatitude=${dLatitude}&dLongitude=${dLongitude}&radius=${radius}&serviceTypeId=${serviceTypeId}` +
      `&vehicleTypeId=${vehicleTypeId}`, 'page-center');
  }


  getNearByVehiclesForRideShare(sLatitude, sLongitude, dLatitude, dLongitude, serviceTypeId): Observable<any> {
    return this.restApiService.get(`/secure/ride-share/near-by-vehicles/eta?slat=${sLatitude}&slong=${sLongitude}&dlat=${dLatitude}` +
      `&dlong=${dLongitude}&serviceTypeId=${serviceTypeId}`, 'page-center');
  }

  getVehicleTypeByServiceId(serviceId?): Observable<any> {
    return this.restApiService.get('/secure/vehicle-types?serviceTypeIds='
      + serviceId, 'page-center');
  }

  getpackages(vehicleTypeId, sLatitude, sLongitude): Observable<any> {
    return this.restApiService.get(`/secure/package/list?vehicleTypeId=${vehicleTypeId}` +
      `&sLatitude=${sLatitude}&sLongitude=${sLongitude}`, 'page-center');
  }

  // stripeCall() {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.src = `https://js.stripe.com/v3/`;
  //   document.getElementsByTagName('head')[0].appendChild(script);

  // }




}
