<div class="ui-g panel-div flexbox" *ngIf="employeeInfo !== null">
  <div class="ui-g-3 ui-md-3 ui-lg-3">
    <img style="width:100%" src="{{imageUrl}}" onerror="this.src='/assets/images/temp/default_profile.png'" />
  </div>
  <div class="ui-g-9 ui-md-9 ui-lg-9 ui-g-nopad position-relative">
    <div class="ui-g">
      <div class="ui-g-12">
        <span class="info-label" translate>label_name </span> : {{employeeInfo.firstName}} {{ employeeInfo.lastName}}
      </div>
      <div class="ui-g-12" *ngIf="employeeInfo.userDetails !== null && employeeInfo.userDetails.referrenceNumber!==''">
        <span class="info-label" translate>lable_employeeID</span> : {{employeeInfo.userDetails.referrenceNumber.trim()}}
      </div>

      <div class="ui-g-12">
        <span class="info-label" translate>phone</span> :
        <span *ngIf="employeeInfo.numCountryCode!==null">+{{employeeInfo.numCountryCode}}</span> {{employeeInfo.phoneNum}}
      </div>

      <div class="ui-g-12">
        <span class="info-label" translate>email</span> : {{employeeInfo.email}}
      </div>

      <div class="ui-g-12" *ngIf="employeeInfo.userDetails!==undefined && employeeInfo.userDetails.departmentName!==null">
        <span class="info-label" translate>label_department</span>
        <span> : {{employeeInfo.userDetails.departmentName}}</span>
      </div> 
    </div> 

  </div>
  <div class="ui-g-12">
    <div class="ui-g-4">
      <button pButton type="button" [label]="labelEdit" icon="fa-pencil" (click)="onEditClick(employeeInfo)"></button>
    </div>
    <div class="ui-g-4">
      <button pButton type="button" [label]="status? Activate : deActivate" icon="{{ status? 'fa-check-circle' : 'fa-ban' }}" [ngClass]="status? 'ui-button-success' : 'ui-button-danger'"
        (click)="onDeactiveClick(employeeInfo.activated)"></button>
    </div>
    <div class="ui-g-4">
      <button pButton type="button" [label]="markAsVIP" (click)="markVIP(employeeInfo.userDetails)" [disabled]="vipMark"></button>
    </div>
  </div>
  
</div>
<p-toast key="message"></p-toast>

<p-dialog modal="true" position="center" responsive="true" ng-style="overflow: auto" styleClass="add-routes-popup" [(visible)]="addEmployeePopup">
  <p-header>
    Edit Employee
  </p-header>
  <app-editable-employee (isEmpPopCancel)="onEmpPopCancel($event)" (isEmpPopSave)="onEmpPopSave($event)" [editEmployeeInfo]="editEmployeeInfo"></app-editable-employee>
</p-dialog>