import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorized-failure-page',
  templateUrl: './authorized-failure-page.component.html',
  styleUrls: ['./authorized-failure-page.component.scss']
})
export class AuthorizedFailurePageComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }

}
