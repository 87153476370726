export const environment = {
  production: false,
  basePath: 'https://micabdev.mi2.in/api',
  tenant: 'tenantIdForTaxiPulse000000000001',
  supportedCountry: ['IN', 'UK', 'US'],
  firebase: {
    apiKey: 'AIzaSyDSxmlXFRGVGS25d_4yM5HlByZPN4aazf4',
    authDomain: 'web-notification-project-382b4.firebaseapp.com',
    databaseURL: 'https://web-notification-project-382b4.firebaseio.com',
    projectId: 'web-notification-project-382b4',
    storageBucket: 'web-notification-project-382b4.appspot.com',
    messagingSenderId: '1058285641208',
    appId: '1:1058285641208:web:e635a934554ce6da890a0d',
    measurementId: 'G-1EY7LX3X0Z'
  }
};
