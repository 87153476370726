import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import { Observable } from 'rxjs';


@Injectable()
export class Grid2Service {

  constructor(private restApiService: RestApiService) { }

  public loadGridData(url: string): Observable<{}> {
    return this.restApiService.get(url, 'page-center');
  }

  public downloadExcelGridData(url: string) {
    return this.restApiService.excel(url, 'export.xls', 'page-center');
  }

  public downloadPdfGridData(url: string) {
    return this.restApiService.pdf(url, 'print.pdf', 'page-center');
  }
}
