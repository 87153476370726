export class PackagesModel {
  partnerType = '';
  baseRate = 0;
  additionalDistanceRate = 0;
  additionalMinuteRate = 0;
  rideTimeFare = 0;
  waitTimeFare = 0;
  cancellationRate = 0;
  distance = 0;
  applicableFrom = 0;

  constructor(option?: {
    baseRate: number;
    distance: number;
    additionalDistanceRate: number;
    additionalMinuteRate: number;
    rideTimeFare: number;
    waitTimeFare: number;
    cancellationRate: number;
    applicableFrom: number;
    partnerType?: string;
  }) {

    if (option) {
      this.partnerType = option.partnerType;
      this.distance = option.distance;
      this.baseRate = option.baseRate;
      this.additionalDistanceRate = option.additionalDistanceRate;
      this.additionalMinuteRate = option.additionalMinuteRate;
      this.rideTimeFare = option.rideTimeFare;
      this.waitTimeFare = option.waitTimeFare;
      this.cancellationRate = option.cancellationRate;
      this.applicableFrom = option.applicableFrom;
    }

  }

}
