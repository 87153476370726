import { Component, OnInit } from '@angular/core';
import { AppSettings } from './../../modules/shared/app.settings';
import { UserCommonService } from './../../modules/shared/services/user-common.service';
import { Subscription } from 'rxjs';
import { UiService } from '../../modules/shared/services/ui.service';
import { StorageService } from './../../modules/shared/services/storage.service';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';
import { SidebarService } from '../../modules/shared/services/sidebar.service';
import { NotificationsService } from './../../modules/shared/services/notifications.service';
import { Message } from 'primeng/api';
import { ErrorBindingService } from '../../modules/shared/services/error-binding.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit {
  signInResponse: any;
  message: Message[] = [];
  otpId;
  forgotPopup = false;
  loginErrors = [{
    email: false,
    password: false,
  }];
  subscription: Subscription;
  isSubmitted = false;
  otpPopup = false;
  setPasswordPopup = false;
  isMetadataLoaded = false;
  otpEmail;
  otpPhone;

  signInWelcomeTextLabel;
  signInDescription;
  readMoreLabel;
  labelResetPassword;
  labelValidateOtp;
  isCompanyRole = false;
  constructor(
    private uiService: UiService,
    private signInService: UserCommonService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private sidebarService: SidebarService,
    private notificationService: NotificationsService,
    private errorBindingService: ErrorBindingService,

  ) { }

  ngOnInit() {
    this.setLabel();
    this.setAppToken();
  }

  setAppToken() {
    const apiToken = this.storageService.getItem(AppSettings.TOKEN_KEY);
    if (apiToken !== undefined && apiToken !== null) {
      this.signInService.getUserProfile().subscribe(results => {
        this.storageService.setItem(AppSettings.USER_DETAILS, results);
        AppSettings.USER = results;
        this.notificationService.canFetchNotification = true;
        this.setServiceAreaId();
      }, (error) => {
        this.errorBindingService.onAPIValidationError(error, this.loginErrors);
      });
    }
  }

  setLabel() {
    this.signInWelcomeTextLabel = this.commonBindingDataService.getLabel('label_sign_in_welcome_text');
    this.signInDescription = this.commonBindingDataService.getLabel('label_sign_in_description');
    this.readMoreLabel = this.commonBindingDataService.getLabel('label_read_more');
    this.labelResetPassword = this.commonBindingDataService.getLabel('forgot_password');
    this.labelValidateOtp = this.commonBindingDataService.getLabel('label_validate_otp');

  }

  setServiceAreaId() {
    this.signInService.getServiceAreaId().subscribe(result => {
      this.storageService.setItem('SERVICE_AREA_ID', result[0].id);
    });
  }

  doSignIn(signInData) {
    this.signInApiCall(signInData);
  }

  signInApiCall(signInData) {
    this.signInService.signIn(JSON.stringify(signInData)).subscribe(signInResult => {
      if (signInResult !== undefined && signInResult.sessionToken !== undefined) {
        this.signInResponse = signInResult;
        if (this.signInResponse.accessGroup !== null && this.signInResponse.accessGroup.length > 0) {
          this.notificationService.canFetchNotification = true;
          this.setSessionAndUserDetails(signInResult);
          this.getUserMetaData();
        } else {
          // this.message = [];
          // this.message.push(
          //   {
          //     severity: 'error',
          //     summary: 'Error',
          //     detail: this.commonBindingDataService.getLabel('error_invalid_access_group')
          //   });
          const invalidAccess = this.commonBindingDataService.getLabel('error_invalid_access_group');
          this.commonBindingDataService.showMessage('Error', invalidAccess);
        }
      }
    }, (error) => {
      // this.errorBindingService.onAPIValidationError(error, this.loginErrors);
      this.commonBindingDataService.showMessage('Error', error.general[0].message);
    });
  }

  setSessionAndUserDetails(results) {
    this.storageService.setItem(AppSettings.TOKEN_KEY, results.sessionToken);
    this.storageService.setItem(AppSettings.USER_DETAILS, results.user);
    this.storageService.setItem(AppSettings.ACCESS_MENU, this.signInResponse.accessGroup);
    AppSettings.HEADER_AUTHORIZATION_VALUE = results.sessionToken;
    AppSettings.USER = results.user;

    this.storageService.setItem(AppSettings.ACCESS_MENU, this.signInResponse.accessGroup);
    if (results.user.userDetails !== null) {
      this.storageService.setItem(AppSettings.CURRENT_USER_CORPORATE_ID, results.user.userDetails.corporateId);
    }
  }

  setRolesAndGenerateSidebar() {
    const userDetails: any = this.storageService.getItem(AppSettings.USER_DETAILS);
    if (userDetails.roles[0].roleCode !== undefined && userDetails.roles[0].roleCode !== null) {
      AppSettings.LOGGED_IN_ROLE = userDetails.roles[0].roleCode;
      this.sidebarService.generateLeftMenuBar();
      this.uiService.profileUpdated();
    } else {
      this.storageService.removeAll();
      const error = {
        general: [
          {
            message: this.commonBindingDataService.getLabel('label_invalid_role')
          }
        ]
      };
      this.errorBindingService.onAPIValidationError(error, this.loginErrors);
    }

    this.isSubmitted = false;
  }

  getUserMetaData() {
    this.signInService.getUserMetaData().subscribe(result => {
      this.setServiceTypes(result.tenantServiceTypeList);
      this.setUserType(result.userTypeList);
      this.setServiceArea(result.tenantServiceAreaList);
      this.setBookingStatus(result.tourStatusConstants);
      this.setBookingStatusForFilter(result.tourStatusConstantsForFilter);
      this.storageService.setItem(AppSettings.TIME_SETTINGS, result.timeSettingsList);
      this.storageService.setItem(AppSettings.TENANT_CONFIG, result.tenantConfigKeys);
      this.storageService.setItem(AppSettings.TENANT_INFO, result.tenantInfo);
      this.storageService.setItem(AppSettings.TENANT_INFO, result.tenantInfo);
      this.setRolesAndGenerateSidebar();
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.loginErrors);
    });
  }

  setBookingStatus(bookingStatus) {
    const bookingStatusArray = [{ label: this.commonBindingDataService.getLabel('label_all'), value: '' }];
    for (let status = 0; status < bookingStatus.length; status++) {
      bookingStatusArray.push({ label: bookingStatus[status].displayValue, value: bookingStatus[status].tourStatusCode });
    }
    this.storageService.setItem('BOOKING_STATUS', bookingStatusArray);
  }

  setBookingStatusForFilter(bookingStatusForFilter) {
    const bookingStatusForFilterArray = [{ label: this.commonBindingDataService.getLabel('label_all'), value: '' }];
    for (let status = 0; status < bookingStatusForFilter.length; status++) {
      bookingStatusForFilterArray.push({
        label: bookingStatusForFilter[status].displayValue,
        value: bookingStatusForFilter[status].tourStatusCode
      });
    }
    this.storageService.setItem('BOOKING_STATUS_FOR_FILTER', bookingStatusForFilterArray);
  }

  setServiceTypes(serviceTypes) {
    const serviceTypeArray: any[] = [
      { label: this.commonBindingDataService.getLabel('please_select'), value: '' },
    ];
    for (let type = 0; type < serviceTypes.length; type++) {
      serviceTypeArray.push({ label: serviceTypes[type].serviceType, value: serviceTypes[type].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_TYPE, serviceTypeArray);
  }

  setUserType(userTypes) {
    const userTypeArray: any[] = [
      { label: this.commonBindingDataService.getLabel('please_select'), value: '' },
    ];
    for (let type = 0; type < userTypes.length; type++) {
      userTypeArray.push({ label: userTypes[type].roleName, value: userTypes[type].roleId });
    }
    this.storageService.setItem(AppSettings.USER_TYPE_LIST, userTypeArray);
  }

  setServiceArea(serviceAreas) {
    const serviceAreaArray: any[] = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    const serviceAreaAliasArray: any[] = [{ label: this.commonBindingDataService.getLabel('please_select'), value: '' }];
    const serviceAreaMapArray: any[] = [{ label: this.commonBindingDataService.getLabel('label_all_cities'), value: AppSettings.TENANT }];
    const multiSelectServiceArea: any[] = [];

    for (let area = 0; area < serviceAreas.length; area++) {
      serviceAreaArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaMapArray.push({ label: serviceAreas[area].cityName, value: serviceAreas[area].id });
      serviceAreaAliasArray.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
      multiSelectServiceArea.push({ label: serviceAreas[area].cityAliasName, value: serviceAreas[area].id });
    }
    this.storageService.setItem(AppSettings.SERVICE_AREA, serviceAreaArray);
    this.storageService.setItem(AppSettings.SERVICE_AREA_ALIAS, serviceAreaAliasArray);
    this.storageService.setItem(AppSettings.SERVICE_CITIES_MAP, serviceAreaMapArray);
    this.storageService.setItem(AppSettings.MUTLTI_SELECT_SERVICE_CITIES, multiSelectServiceArea);
  }

  moduleWiseRouteAccesses() {
    const groups = {};
    let accesses = this.signInResponse.accesses;
    for (let i = 0; i < accesses.length; i++) {
      const accessGroupName = accesses[i].accessGroupName;
      if (!groups[accessGroupName]) {
        groups[accessGroupName] = [];
      }
      groups[accessGroupName].push(accesses[i].accessName);
    }
    accesses = [];
    for (const accessGroupName in groups) {
      if (groups.hasOwnProperty(accessGroupName)) {
        accesses.push({ group: accessGroupName, accessName: groups[accessGroupName] });
      }
    }
    this.storageService.setItem(AppSettings.ACCESS_LIST, accesses);
  }

  uniqArray(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) === pos;
    });
  }

  forgotPassword() {
    this.forgotPopup = true;
  }

  popClose(event) {
    this.forgotPopup = false;
  }

  otpPopClose(event) {
    this.otpPopup = false;
  }

  setPasswordPopClose(event) {
    this.setPasswordPopup = false;
  }

  otpPopSave(event) {
    // debugger
    // if (event.general !== undefined) {
    //   this.message = [];
    //   this.message.push({
    //     severity: 'error',
    //     summary: 'OTP',
    //     detail: event.general[0].message
    //   });
    // } else {
    if (event.otpId) {
      this.otpId = event.otpId;
      // this.message = [];
      // this.message.push({
      //   severity: 'success',
      //   summary: 'OTP Verified',
      //   detail: 'OTP matched.'
      // });
      this.commonBindingDataService.showMessage('Success', 'OTP matched.', 'OTP Verified');
      this.setPasswordPopup = true;
    } else {
      this.commonBindingDataService.showMessage('Error', event.general[0].message, 'OTP');
    }
    this.forgotPopup = false;
    this.otpPopup = false;
  }

  popSave(results) {
    this.otpEmail = results.email;
    this.otpPhone = results.phone;
    this.commonBindingDataService.showMessage('Success', results.general[0].message, 'Forgot Password');
    this.forgotPopup = false;
    this.otpPopup = true;
  }
}

