<div class="driver-details" *ngIf="driverDetails!==null">
  <div class="p-grid panel-div">
    <div class="p-col-5 p-md-3 p-lg-3">
      <img class="user-pic" src="{{imageUrl}}" onerror="src='/assets/images/temp/default_profile.png'" />
    </div>
    <div class="p-col-7 p-md-9 p-lg-9 mrg-top-10">
      <div class="p-grid">

        <div class="p-grid">
          <div class="p-col-6">
            <div class="info-label" translate>label_driver_details_header</div>
          </div>
          <div class="p-col-6" *ngIf="bookingCode !== null && bookingCode !== undefined && bookingCode !== ''" (click)="trackDriverLocations(driverDetails.id)">
            <i class="fa fa-map-marker track-driver"> Track Driver</i>
          </div>
        </div>
        <div class="p-col-5">
          <span class="info-label" translate>label_name</span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div>{{driverDetails.firstName }} {{ driverDetails.lastName}}</div>
        </div>
        <div class="p-col-5">
          <div class="info-label" translate>label_phone</div>
        </div>
        <div class="p-col-7">
          <div>{{ driverDetails.phoneNum }}</div>
        </div>
        <div class="p-col-5">
          <div class="info-label" translate>label_email</div>
        </div>
        <div class="p-col-7">
          <div>{{ driverDetails.email }}</div>
        </div>
      </div>
      <div class="p-grid" *ngIf='driverDetails.rating !== null && driverDetails.rating!==0'>
        <div class="p-col-5">
          <span class="info-label" translate>label_rating </span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div class="image-code image-rating-width" [ngClass]="{'1':'rating1','2':'rating2','3':'rating3','4':'rating4','5':'rating5'}[driverDetails.rating]"></div>
        </div>
      </div>

      <div class="p-grid" *ngIf='vehicleDetails !== null && vehicleDetails.makeModel !== null'>
        <div class="p-col-5">
          <span class="info-label" translate>label_CMM</span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div>{{ vehicleDetails.makeModel }}</div>
        </div>
      </div>

      <div class="p-grid" *ngIf='vehicleDetails !== null && vehicleDetails.modelName !== null'>
        <div class="p-col-5">
          <span class="info-label" translate>label_VM_name</span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div>{{ vehicleDetails.modelName }}</div>
        </div>
      </div>

      <div class="p-grid" *ngIf='vehicleDetails !== null && vehicleDetails.vehicleNumber !== null'>
        <div class="p-col-5">
          <span class="info-label" translate>label_vehicleNumber </span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div>{{ vehicleDetails.vehicleNumber }}</div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="p-grid panel-div" *ngIf="driverDetails===null">
  <div class="p-col-12">
    <div class="info-label" translate>message_driver_not_assigned</div>
  </div>
</div>