<div class="ui-g-6 ui-md-12">
  <div class="activity-box">
    <div class="ui-g activity-header-area">
      <h4 class="activity-header">{{headerLHS}}</h4>
    </div>
    <div class="activity-body-area">
      <div class="ui-g" *ngFor="let notifs of activityNotifications">
        <div class="ui-g-11 ui-md-10">
          <div class="activity-content">{{notifs.nitfication}}</div>
        </div>
        <div class="ui-g-1 ui-md-2">
          <div class="activity-time">{{notifs.time}}</div>
        </div>
      </div>
      <div class="ui-g">
        <span class="show-all-notifs">View All</span>
      </div>
    </div>
  </div>
</div>

<div class="ui-g-6 ui-md-12">
  <div class="activity-box">
    <div class="ui-g activity-header-area">
      <h4 class="activity-header">{{headerRHS}}</h4>
    </div>
    <div class="activity-body-area">
      <div class="ui-g" *ngFor="let notifs of activityNotifications">
        <div class="ui-g-11 activity-content">{{notifs.nitfication}}</div>
        <div class="ui-g-1 activity-time">{{notifs.time}}</div>
      </div>
      <div class="ui-g">
        <span class="show-all-notifs">View All</span>
      </div>
    </div>
  </div>
</div>