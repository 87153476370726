import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-total-and-active-counts',
  templateUrl: './total-and-active-counts.component.html'
})
export class TotalAndActiveCountsComponent implements OnInit {
  @Input() totalAndActiveCounts;

  constructor() {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }


}
