import { Component, OnInit, Input } from '@angular/core';
import { AppSettings } from './../../app.settings';

@Component({
  selector: 'app-rental-docs',
  templateUrl: './rental-docs.component.html'
})
export class RentalDocsComponent implements OnInit {
  @Input() rentalDocs;
  profileImageUrl: string = AppSettings.GET_FILE_THUMB_URL;
  profileOrignalImageUrl: string = AppSettings.GET_FILE_URL;

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }



}
