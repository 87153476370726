<div class="p-grid page-header">
  <div class="p-col-12 p-md-6 p-lg-6">
    <h1 class="page-title">{{headerStr}}</h1>
  </div>
  <!-- <div class="p-col-12 p-md-6 p-lg-6">
    <div class="bread-crumbs">
      <li class="bread-item" *ngFor='let bread of breadCrumArray'>
        <a href="{{bread.url}}"> &nbsp;{{bread.name}}</a>
      </li>
    </div>
  </div> -->
</div>