import { AppSettings } from './../../app.settings';
import { Component, Input } from '@angular/core';
import { Message } from 'primeng/api';

declare const google: any;

@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html'
})
export class TripMapComponent {
  @Input() tripMapDetails;
  message: Message[] = [];
  map: any;
  directionsService = new google.maps.DirectionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  constructor() { }

  calculateAndDisplayRoute(origin, destination, currentLocation?) {
    const map = new google.maps.Map(document.getElementById('tripMap'), AppSettings.MAP_OPTION);
    const directionsDisplay = new google.maps.DirectionsRenderer;
    directionsDisplay.setMap(map);

    this.directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: 'DRIVING'
    }, function(response, status) {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
        if (currentLocation) {
          this.marker = new google.maps.Marker;
          this.marker.setMap(map);
          this.marker.setIcon('/assets/images/temp/taxi_busy.png');
          this.marker.setPosition(currentLocation);
        }
      } else {
        this.mapMessage(status);
      }
    });
  }

  mapMessage(status) {
    this.message = [];
    this.message.push({ severity: 'success', summary: 'Confirmed', detail: status });
  }
}
