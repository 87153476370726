<div class="driver-details" *ngIf="passengerDetails !== null">
  <div class="p-grid panel-div">
    <div class="p-col-12 p-md-3 p-lg-3 p-sm-12">
      <img class="user-pic user-pic-sm" src="{{imageUrl}}" onerror="src='/assets/images/temp/default_profile.png'" />
      <div class = "text-center text-left-sm">
        <p-rating [ngModel]="Rating" readonly="true" stars="5" [cancel]="false" style="font-size:21px;"></p-rating>
      </div>
      </div>
    <div class="p-col-12 p-md-9 p-lg-9 p-g-nopad mrg-top-10">
      <div class="p-grid word-break">
        <div class="p-col-12">
          <div class="info-label" translate>label_passenger_details_header</div>
        </div>
        <div class="p-col-5">
          <span class="info-label" translate>label_name</span>
          <span class="info-label">: </span>
        </div>
        <div class="p-col-7">
          <div>{{passengerDetails.firstName }} {{ passengerDetails.lastName}}</div>
        </div>
        <div class="p-col-5">
          <div class="info-label" translate>label_phone</div>
        </div>
        <div class="p-col-7">
          <div>
            <span *ngIf="passengerDetails.numCountryCode!==null">{{passengerDetails.numCountryCode}} {{ passengerDetails.phoneNum }}</span>
          </div>
        </div>
        <div class="p-col-5">
          <div class="info-label" translate>label_email</div>
        </div>
        <div class="p-col-7">
          <div>{{ passengerDetails.email }}</div>
        </div>
        <div class="p-grid p-col-12 " *ngIf='passengerDetails.rating !== null && passengerDetails.rating > 0'>
          <div class="p-col-5">
            <div class="info-label" translate>rating_col</div>
          </div>
          <div class="p-col-7">
            <div>{{ passengerDetails.passengerRating }}</div>
          </div>
        </div>

        <div class="p-grid p-col-12" *ngIf='passengerDetails.addressLine1 !== null && passengerDetails.addressLine1 !== undefined && passengerDetails.addressLine1 !== ""'>
          <div class="p-col-5">
            <div class="info-label" translate>address_col</div>
          </div>
          <div class="p-col-7">
            <div>
              <span>{{ passengerDetails.addressLine1 }}</span>
              <span *ngIf='passengerDetails.addressLine2!== null && passengerDetails.addressLine2!== undefined && passengerDetails.addressLine2 !== ""'>
                {{passengerDetails.addressLine2}}
              </span>
              <span *ngIf='city !== "" && city !== undefined'>, {{city}}</span>
              <span>, {{country}}</span>
              <span>, {{passengerDetails.zipcode}}</span>
            </div>

          </div>
        </div>

        <div class="p-col-12 p-grid" *ngIf='bookingsCount !== null'>
          <div class="p-col-5">
            <div class="info-label" translate>noOfBookings</div>
          </div>
          <div class="p-col-7">
            <div>{{ bookingsCount }}</div>
          </div>
        </div>
        <div class="p-grid p-col-12" *ngIf="this.isIdologyDriverVerification === 'IDOLOGY'">
          <div class="p-col-3 p-sm-4" >
            <div class="info-label" translate>label_verified_by_third_party</div> :
          </div>
          <div class="p-col-9 p-sm-8 driver-inline-flex">
            <div>
              <span style="padding:4px;"> {{ passengerDetails.verifiedByThirdParty }}</span>
            </div>
          </div>
          <div class="p-col-3 p-sm-4" >
            <div class="info-label" translate>label_verified_by_third_party_at</div> :
          </div>
          <div class="p-col-9 p-sm-8 driver-inline-flex">
            <div>
              <span style="padding:4px;"> {{ passengerDetails.verifiedByThirdPartyAt | date}}</span>
            </div>
          </div>
        </div>
      

      </div>
    </div>
  </div>
</div>
<div class="p-grid panel-div" *ngIf="passengerDetails === null">
  <div class="p-col-12">
    <div class="info-label" translate>message_passenger_not_assigned</div>
  </div>
</div>