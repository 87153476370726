<div class="stripe-form">
  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
    <div class="form-row">
      <label for="card-info" translate="">lbl_credit_card_info</label>
      <div id="card-info" #cardInfo></div>

      <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
    </div>
    <!-- <button type="submit" [disabled]="paymentProcessing" *ngIf="payment">Pay ${{ payment.amount}}</button> -->
    <button type="submit" [disabled]="paymentProcessing" *ngIf="payment">Pay</button>
    <div style="margin-top:10px">
      <span class="payment-failed" *ngIf="paymentFailed">
        Payment failed, please try again.
      </span>
    </div>
  </form>
</div>
<p-toast key="message"></p-toast>