export class RouteRate {
  routePackgeDestinationId = '';
  routeRate = 0;
  partnerId = '';
  constructor(option?: {
    routePackgeDestinationId: string,
    routeRate: number,
    partnerId: string,
  }) {
    if (option) {
      this.partnerId = option.partnerId;
      this.routePackgeDestinationId = option.routePackgeDestinationId;
      this.routeRate = option.routeRate;
    }

  }

}
