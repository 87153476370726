import { Injectable } from '@angular/core';

import { RestApiService } from '../../shared/services/rest-api.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';

@Injectable()
export class ManageEmployeeService {

  constructor(private restApiService: RestApiService) { }

  addORUpdate(data, corporateId?, id?): Observable<any> {
    if (id !== null && id !== undefined) {
      return this.restApiService.put('/secure/corporates/' + corporateId + '/employees/' + id, data, 'page-center');
    } else {
      return this.restApiService.post('/secure/corporates/' + corporateId + '/employees', data, 'page-center');
    }
  }

  departmentOption(id): Observable<any> {
    return this.restApiService.get('/corporates?corporateId=' + id, 'page-center');
  }

  getEmployeeDetails(id, bookingId?): Observable<any> {
    if (bookingId !== undefined) {
      return this.restApiService.get('/secure/corporates/employees/' + id + '/' + bookingId, 'page-center');
    } else {
      return this.restApiService.get('/secure/corporates/employees/' + id, 'page-center');
    }
  }

  markVIP(id): Observable<any> {
    return this.restApiService.put(`/secure/corporates/employees/${id}/vip`, 'page-center');
  }

  activeDeactive(status, id): Observable<any> {
    return this.restApiService.put(`/secure/corporates/employees/${id}/status?active=${status}`, 'page-center');
  }

  employyeApproveRequest(employee, corpId): Observable<any> {
    return this.restApiService.put(`/secure/corporates/${corpId}/employees/${employee.id}
    /approve?employeeCode=${employee.code}`, 'page-center');
  }

  employyeRejectRequest(id): Observable<any> {
    return this.restApiService.put(`/secure/corporates/employees/${id}/reject`, 'page-center');
  }
}
