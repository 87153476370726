
  <div class="form-field ui-g-12 ui-md-3 ui-lg-3">
    <div class="form-label">
      <label for="startDate" translate>label_from_date</label>
    </div>
    <div class="form-control">
      <p-calendar [(ngModel)]="fromDate" [showIcon]="true" dateFormat="{{dateFormat}}"
        (onSelect)="changeFilter($event)"></p-calendar>

    </div>
  </div>

  <div class="form-field ui-g-12 ui-md-3 ui-lg-3">
    <div class="form-label">
      <label for="toDate" translate>label_to_date</label>
    </div>
    <div class="form-control">
      <p-calendar [(ngModel)]="toDate" [showIcon]="true" dateFormat="{{dateFormat}}"
        (onSelect)="changeFilter($event)"></p-calendar>
    </div>
  </div>
  <div class="ui-g-12 ">
    <app-mi-grid-v2 [url]="url" [columnDefs]="columns"
      rowSelection="multiple" divClass="mi-grid"></app-mi-grid-v2>
  </div>