<div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad">
  <div class="ui-g form-container">
    <form [formGroup]="editRoutesForm">
      <div class="ui-g">
        <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
          <div class="form-errors alert alert-danger " [hidden]='!routeApiError[0]["general"]'>
            <div *ngFor="let ErrorMessage of routeApiError['general'] ">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="packagesModel !==null ">
        <div class="ui-g-3 ui-md-3 ui-lg-3">
          <div class="info-label " translate>
            label_route_name
          </div>
        </div>
        <div class="ui-g-9">
          <div>{{packagesModel.routeName}}</div>
        </div>
        <div class="ui-g-3">
          <div class="info-label " translate>
            label_pickup_location
          </div>
        </div>
        <div class="ui-g-9">
          <div>{{packagesModel.pickupLocationName}}</div>
        </div>

      </div>

      <div class="ui-g-12 ">
        <div class="ui-g-9">
          <div class="info-label " translate>
            label_destination
          </div>
        </div>
        <div class="info-label ">
          Fare
          <label class="asterisk">*</label>
        </div>
      </div>

      <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad" *ngFor="let value of values ">

        <div class="form-field ui-g-12" [ngClass]="(!value.activated)? 'disabled-row':'enabled-row'">
          <div class="ui-g-9 ">
            <div>{{value.destinationsName}}</div>
          </div>
          <div class="ui-g-3 form-control">
            <input pInputText type="text" [appDisableControl]="!value.activated" [placeholder]='Fare' [formControlName]="value.fareformControlName "
            />

            <div class="form-errors " *ngIf="editRoutesForm.controls[value.fareformControlName].errors &&
            (editRoutesForm.controls[value.fareformControlName].dirty ||
             editRoutesForm.controls[value.fareformControlName].touched) ">
              <div [hidden]="!editRoutesForm.controls[value.fareformControlName].errors.required " translate>
                route_fare
              </div>
              <div [hidden]="!editRoutesForm.controls[value.fareformControlName].errors.pattern " translate>
                invalid
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-buttons ui-g-12 ">
        <button class="negative-button" pButton type="button " [label]="Cancel " (click)="cancel() "></button>
        <button pButton type="button " [label]="Save " [disabled]="!editRoutesForm.valid " (click)="editRoutePackages() "></button>
      </div>
      <p-toast key="message"></p-toast>
    </form>
  </div>
</div>