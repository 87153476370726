export class EditableRentalModel {
  packageName = '';
  distance = 0;
  minutes = 0;
  addKm = 0;
  addHr = 0;
  cancelFee = 0;
  pricingId = '';
  activated = false;

  constructor(option?: {
    packageName: string;
    distance: number;
    minutes: number;
    additionalDistanceRate: number;
    additionalMinuteRate: number;
    cancellationRate: number;
    id?: string;
    activated?: boolean;
  }) {

    if (option) {
      this.packageName = option.packageName;
      this.distance = option.distance;
      this.minutes = option.minutes;
      this.addKm = option.additionalDistanceRate;
      this.addHr = option.additionalMinuteRate;
      this.cancelFee = option.cancellationRate;
      this.pricingId = option.id;
      this.activated = option.activated;
    }
  }
}
