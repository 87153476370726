import { DestinationsModel } from './destinations-models';

export class EditableRoutesModel {

  tenantId = '';
  serviceAreaId = '';
  serviceTypeId = '';
  routeName = '';
  vehicleTypeId = '';
  radius = 0;
  longitude = 0;
  latitude = 0;
  pickupLocationName = '';
  routePackagesDestinations: DestinationsModel[] = [];
  constructor(option?: {
    tenantId: string;
    serviceAreaId: any;
    serviceTypeId: any;
    routeName: string;
    vehicleTypeId: any;
    radius: number;
    longitude: number;
    latitude: number;
    pickupLocationName: string;
    routePackagesDestinations: DestinationsModel[];
  }) {

    if (option) {
      this.tenantId = option.tenantId;
      this.serviceAreaId = option.serviceAreaId;
      this.serviceTypeId = option.serviceTypeId;
      this.routeName = option.routeName;
      this.vehicleTypeId = option.vehicleTypeId;
      this.radius = option.radius;
      this.longitude = option.longitude;
      this.latitude = option.latitude;
      this.pickupLocationName = option.pickupLocationName;
      this.routePackagesDestinations = option.routePackagesDestinations;
    }

  }
}
