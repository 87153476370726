import { AppSettings } from './../../../shared/app.settings';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Message } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ManageEmployeeService } from './../../../corporate-manage-employee/services/manage-employee.service';


@Component({
  selector: 'app-employee-user-details',
  templateUrl: './employee-user-details.component.html',
  providers: [ManageEmployeeService]
})
export class EmployeeUserDetailsComponent implements OnInit, OnChanges {
  message: Message[] = [];
  @Input() employeeInfo: any;
  @Input() id;
  @Output() UpdateEmployee: EventEmitter<any> = new EventEmitter();
  status;
  editEmployeeInfo: any;
  addEmployeePopup = false;
  imageUrl;
  markAsVIP;
  deActivate;
  labelEdit;
  Activate;
  vipMark: boolean;
  constructor(
    private manageEmployeeService: ManageEmployeeService,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.imageUrl = AppSettings.DEFAULT_PROFILE_IMAGE_URL;
    this.markAsVIP = this.commonBindingDataService.getLabel('label_mark_vip');
    this.labelEdit = this.commonBindingDataService.getLabel('label_edit');
    this.Activate = this.commonBindingDataService.getLabel('label_activate');
    this.deActivate = this.commonBindingDataService.getLabel('label_deactivate');
  }
  ngOnChanges() {
    if (this.employeeInfo !== undefined && this.employeeInfo !== null) {
      this.status = this.employeeInfo.activated;
      if (this.employeeInfo.userDetails && this.employeeInfo.userDetails !== null) {
        this.imageUrl = AppSettings.GET_FILE_THUMB_URL + this.employeeInfo.userDetails.profileImage;
        this.vipMark = this.employeeInfo.userDetails.vip;
      }
    }
  }

  onDeactiveClick(status) {
    this.manageEmployeeService.activeDeactive(status, this.id).subscribe(results => {
      this.dispalyMessage(results, 'success');
      this.employeeInfo.activated = !this.employeeInfo.activated;
      this.status = this.employeeInfo.activated;
    }, (error) => {
      console.log(error);
      this.dispalyMessage(error, 'error');
    });
  }

  onEditClick(event) {
    if (event !== null && event !== undefined) {
      if (event.userDetails !== null && event.userDetails !== undefined) {
        if (event.lastName === null) {
          event.lastName = '';
        }
        const data = {
          id: event.id,
          employeeNameStr: event.firstName + ' ' + event.lastName,
          employeeId: event.userDetails.referrenceNumber,
          vip: event.userDetails.vip,
          departmentId: event.userDetails.departmentId,
          phoneNum: event.phoneNum,
          email: event.email,
          numCountryCode: event.numCountryCode,
          profile: event.userDetails.profileImage
        };
        this.editEmployeeInfo = data;
      }
    }
    this.addEmployeePopup = true;
  }

  markVIP(event) {
    this.manageEmployeeService.markVIP(this.id).subscribe(results => {
      this.UpdateEmployee.emit();
      this.vipMark = true;
      this.dispalyMessage(results, 'success');
    }, (error) => {
      this.dispalyMessage(error, 'error');
    });

  }

  dispalyMessage(msgObj, severity) {
    this.message = [];
    msgObj.general.forEach(element => {
      this.message.push({
        severity: severity,
        summary: severity.charAt(0).toUpperCase() + severity.slice(1),
        detail: element.message
      });
    });
  }
  onEmpPopCancel(event: any) {
    this.addEmployeePopup = !this.addEmployeePopup;
  }


  onEmpPopSave(event) {
    this.addEmployeePopup = !this.addEmployeePopup;
    this.message = [];
    this.message.push({ severity: 'success', summary: 'Confirmed', detail: event.general[0].message });
    this.UpdateEmployee.emit();
  }
}
