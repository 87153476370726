<div class="p-grid form-container">
    <div class="p-col-12">
      <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
        <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
          {{ErrorMessage.message}}
        </div>
      </div>
    </div>
    
    <form class="p-col-12 " [formGroup]="bookingCancelform">
      <div class="form-field">
        <div class="form-control">
          <!-- <textarea formControlName="cancelReason" [placeholder]="label_Cancelation" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"></textarea> -->
          <p-autoComplete field="driverName"  [suggestions]="filteredDrivers" formControlName="driverName" (completeMethod)="search($event)" placeholder="Please select drivers" [size]="50" [dropdown]="true"></p-autoComplete>
        </div>
        <div class="form-errors" *ngIf="bookingCancelform.controls.driverName.errors  && (bookingCancelform.controls.driverName.dirty || bookingCancelform.controls.driverName.touched)">
          <div [hidden]="!bookingCancelform.controls.driverName.errors.required" translate>
              label_driver_required
          </div>
        </div>
      </div>
      <div class="p-col-12  form-buttons" style="padding-top: 33px;">
        <button pButton type="button" [label]="labelAssign" icon="fa fa-check" [disabled]="!bookingCancelform.valid" (click)="assignDriver()"></button>
        <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel" (click)="cancel()"></button>
      </div>
    </form>
  </div>
  
  <p-toast key="message"></p-toast>