import { StorageService } from './../../shared/services/storage.service';
import { CommonBindingDataService } from './../../shared/services/common-binding-data.service';
import { RestApiService } from './../../shared/services/rest-api.service';
import { formatDateCell } from 'app/modules/shared/utility-functions/grid';
import { AppSettings } from './../../shared/app.settings';
import { Injectable } from '@angular/core';

import { MiGrid2Component } from 'app/modules/shared/components/mi-grid-2/mi-grid-2.component';

@Injectable()
export class HotelBookingService {
  gridColumn: any;
  gridRef2: MiGrid2Component;

  url = '/secure/hotels/bookings/grid?hotelid=';
  colummns: any = [];

  constructor(private restApiService: RestApiService,
    private commonService: CommonBindingDataService,
    private storageService: StorageService
  ) {
    this.colummns.push({
      field: 'bookingCode', header: this.commonService.getLabel('label_booking_ID'),
      sort: true, resize: true
    });
    this.colummns.push({
      field: 'createdAt', header: this.commonService.getLabel('label_booking_Date'),
      sort: true, resize: true, cellRenderer: (i) => (formatDateCell(i))
    });
    this.colummns.push({
      field: 'passengerName', header: this.commonService.getLabel('label_passenger_name'),
      sort: true, resize: true
    });
    this.colummns.push({
      field: 'serviceType', header: this.commonService.getLabel('service_type'),
      sort: true, resize: true
    });
    this.colummns.push({
      field: 'vehicleType', header: this.commonService.getLabel('label_category_type'),
      sort: true, resize: true
    });
    this.colummns.push({
      field: 'pickupLocation', header: this.commonService.getLabel('label_pickup_drop_location'),
      sort: true, resize: true, cellRendererHtml: (i) => (this.pickDropInfo(i))
    });
    this.colummns.push({
      field: 'pickupDateTime', header: this.commonService.getLabel('lable_pickup_date_time'),
      sort: true, resize: true, cellRenderer: (i) => (formatDateCell(i))
    });
    this.colummns.push({
      field: 'bookingStatusStr', header: this.commonService.getLabel('label_booking_status'),
      sort: true, resize: true
    });
    this.colummns.push({
      field: '', header: this.commonService.getLabel('label_actions'),
      sort: false, resize: true, cellRendererHtml: (i) => (this.actionCells(i))
    });
  }

  getColumn() {
    return this.colummns;
  }

  setGridRef(gridRef) {
    this.gridRef2 = gridRef;
  }
  formatDate(params) {
    return formatDateCell(params.pickupDatetime);
  }

  formatBookingDate(params) {
    return formatDateCell(params.createdAt);
  }

  pickDropInfo(params) {
    let lblActivate = '';
    if (params.pickupLocationName) {
      lblActivate = `<div title='pickup location'>` + params.pickupLocationName + `</div><div title='drop location'>`
        + params.dropLocationName + `</div>`;
    }
    const eDiv = document.createElement('div');
    eDiv.setAttribute('class', 'grid-comman-col');
    eDiv.innerHTML = lblActivate;
    return eDiv;
  }

  actionCells(params) {
    const status = params.bookingStatus;
    const bookLater = params.bookLater;
    const bookingServiceType = params.serviceType;
    const eDiv = document.createElement('div');

    const lblCancelBooking = `<span data-action-type='cancel' class='grid-btn activate' title='Cancel'>
                        <i data-action-type='cancel' class='fa fa-fw fa-ban' aria-hidden='true'></i>
                    </span>`;

    let lblResend = `<span data-action-type='resendCode' class='grid-btn assign' title='Resend Code'>
                      <i data-action-type='resendCode' class='fa fa-fw fa-key' aria-hidden='true'></i>
                    </span>`;
    eDiv.setAttribute('class', 'grid-btn-div');

    if (AppSettings.BOOOKING_QUOTATION_ACCEPTED.indexOf(status) >= 0) {
      let lblAssign;
      lblResend = '';
      lblAssign = `<span data-action-type='assign' class='grid-btn assign' title='Assign'>
                      <i data-action-type='assign' class='fa fa-fw fa-taxi' aria-hidden='true'></i>
                     </span> `;
      if (bookLater!) {
        lblAssign = '';
      }
      eDiv.innerHTML = lblAssign + lblCancelBooking + lblResend;
    } else if (AppSettings.ASSIGN_BOOKING_STATUS_ARRAY.indexOf(status) >= 0) {
      let lblAssign;
      lblResend = '';
      if (AppSettings.RENTAL_SERVICE_TYPE.indexOf(bookingServiceType) === -1 && (bookLater)) {
        lblAssign = `<span data-action-type='assign' class='grid-btn assign' title='Assign'>
                      <i data-action-type='assign' class='fa fa-fw fa-taxi' aria-hidden='true'></i>
                     </span> `;
      } else {
        lblAssign = '';
      }
      eDiv.innerHTML = lblAssign + lblCancelBooking + lblResend;
    } else if (AppSettings.REASSIGN_BOOKING_STATUS_ARRAY.indexOf(status) >= 0) {
      let lblAssign;
      if (AppSettings.RENTAL_SERVICE_TYPE.indexOf(bookingServiceType) === -1) {
        lblAssign = '';
      } else {
        lblAssign = '';
      }
      eDiv.innerHTML = lblAssign + lblCancelBooking + lblResend;
    } else if (AppSettings.COMPLETE_BOOKING_STATUS_ARRAY.indexOf(status) >= 0) {
      eDiv.innerHTML = '';
    }
    return eDiv;
  }



  gridBind() {
    if (this.gridRef2 !== undefined && this.gridRef2 !== null) {
      this.gridRef2.updateUrl(this.url);
    }
  }

  changeServiceType(params) {
    if (this.gridRef2 !== undefined && this.gridRef2 !== null) {
      this.gridRef2.updateUrl(this.url + params);
    }
  }
}
