import { Observable } from 'rxjs';
import { RestApiService } from './../../shared/services/rest-api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ApprovalService {

  constructor(private restApiService: RestApiService) { }

  handleApprove(partnerid, bookingid, status): Observable<any> {
    return this.restApiService.put(`/secure/corporates/${partnerid}/booking/${bookingid}/approval-status?status=${status}`);
  }

  departmentOption(id): Observable<any> {
    return this.restApiService.get('/corporates?corporateId=' + id, 'page-center');
  }
}
