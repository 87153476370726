import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PackagesModel } from './../../../hotels/models/package.model';
import { AppSettings } from './../../app.settings';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { ErrorBindingService } from './../../services/error-binding.service';

@Component({
  selector: 'app-edit-taxi-partner-pricing',
  templateUrl: './edit-taxi-partner-pricing.component.html'
})
export class EditTaxiPartnerPricingComponent implements OnInit {

  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Input() settingApiError;
  @Input() partnerType;
  pricingId = null;
  packageId = null;
  Save;
  Cancel;
  editTaxiForm = new FormGroup({
    base_rate: new FormControl(),
    distance: new FormControl(),
    distance_rate: new FormControl(),
    wait_rate: new FormControl(),
    ride_rate: new FormControl(),
    cancellation_rate: new FormControl()
  });
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private errorBindingService: ErrorBindingService,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.Save = this.commonBindingDataService.getLabel('label_save');
    this.Cancel = this.commonBindingDataService.getLabel('label_cancel');
    this.editTaxiForm = this.formBuilder.group({
      base_rate: ['', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      distance: ['', [Validators.required]],
      distance_rate: ['', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      wait_rate: ['', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      ride_rate: ['', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]],
      cancellation_rate: ['', [Validators.required, Validators.pattern(AppSettings.FEE_PATTERN)]]
    });
  }

  bindData(data, Id) {
    this.editTaxiForm.controls.distance.setValue(
      this.commonBindingDataService.unitConversionMToKm(data.distance));
    this.editTaxiForm.controls.base_rate.setValue(data.baseRate);
    this.editTaxiForm.controls.distance_rate.setValue(data.additionalDistanceRate);
    this.editTaxiForm.controls.ride_rate.setValue(data.rideTimeFare);
    this.editTaxiForm.controls.wait_rate.setValue(data.waitTimeFare);
    this.editTaxiForm.controls.cancellation_rate.setValue(data.cancellationRate);
    this.packageId = data.id;
    this.pricingId = Id;
  }
  editRoutePackages() {
    const data = new PackagesModel({
      partnerType: this.partnerType,
      distance: this.commonBindingDataService.unitConversionKMToM(
        this.editTaxiForm.controls.distance.value),
      additionalDistanceRate: this.editTaxiForm.controls.distance_rate.value,
      additionalMinuteRate: 0,
      applicableFrom: new Date().getTime(),
      baseRate: this.editTaxiForm.controls.base_rate.value,
      cancellationRate: this.editTaxiForm.controls.cancellation_rate.value,
      rideTimeFare: this.editTaxiForm.controls.ride_rate.value,
      waitTimeFare: this.editTaxiForm.controls.wait_rate.value
    });

    this.commonBindingDataService.setPackages(data, this.packageId, this.pricingId).subscribe((results) => {
      this.editTaxiForm.reset();
      this.isPopSave.emit(results);
    }, (error) => {
      this.errorBindingService.onAPIValidationError(error, this.settingApiError);
    });
  }
  cancel() {
    this.editTaxiForm.reset();
    this.isPopCancel.emit(false);
  }

}
