<div class="track-my-location-container ui-g">
    <div class="track-my-location">
  
      <div class="header ui-g-12 ui-md-12 ui-lg-12 ui-sm-12">
        <div class="ui-g-6 ui-md-12 ui-lg-6 ui-sm-12">
          <a href="" target="_blank">
            <img width="200" src="/assets/images/temp/MI_black_logo.png">
          </a>
        </div>
      </div>
      <div class=" ui-g-nopad ui-g-12 ui-md-12 ui-lg-12">
        <div class="seperator"></div>
      </div>
  <div align="center" class="form-container">
      <form class="p-lg-12 p-md-12 p-sm-12" [formGroup]="walletTopupForm" style="padding-top:99px;">
        <div class="white-patch">

        <h2>Wallet Topup</h2>
            <div class="form-field p-md-4 p-lg-4 p-sm-12 white-patch">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Card Number</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="form-control">
                  <input type="text" style="background-color: #eaeaea;" [placeholder]="" name="" id="tbNum" disabled formControlName="cardNumber" />
                </div>
                <!-- <div class="form-errors"
                  *ngIf="walletTopupForm.controls.cardNumber.errors  && (walletTopupForm.controls.cardNumber.dirty || walletTopupForm.controls.cardNumber.touched)">
                  <div [hidden]="!walletTopupForm.controls.cardNumber.errors.required" translate>
                    Card number is required.
                  </div>
                  <div [hidden]="!walletTopupForm.controls.cardNumber.errors.pattern" translate>
                    Invalid Card Number. 
                </div>
                </div> -->
              </div>
            <div class="form-field p-md-4 p-lg-4 p-sm-12 white-patch">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Amount</label>
                  <label class="asterisk">*</label>
                </div>
                <div class="form-control">
                  <input type="text" [placeholder]="" name="" pInputText formControlName="amount" />
                </div>
                <div class="form-errors"
                  *ngIf="walletTopupForm.controls.amount.errors  && (walletTopupForm.controls.amount.dirty || walletTopupForm.controls.amount.touched)">
                  <div [hidden]="!walletTopupForm.controls.amount.errors.required" translate>
                    Amount is required.
                  </div>
                  <div [hidden]="!walletTopupForm.controls.amount.errors.pattern" translate>
                   Invalid amount.
                  </div>
                </div>
            </div>
            <div class="form-field p-md-4 p-lg-4 p-sm-12 white-patch">
                <div class="form-label left-align">
                  <label for="pickup-location" translate>Card Code</label>
                   <label class="asterisk">*</label> 
                     </div>
                <div class="form-control">
                  <input type="text" [placeholder]="" name="" pInputText formControlName="cardCode" />
                </div>
                <div class="form-errors"
                  *ngIf="walletTopupForm.controls.cardNumber.errors  && (walletTopupForm.controls.cardNumber.dirty || walletTopupForm.controls.cardNumber.touched)">
                  <div [hidden]="!walletTopupForm.controls.cardNumber.errors.required" translate>
                    Card Code is required.
                  </div>
                  <div [hidden]="!walletTopupForm.controls.cardNumber.errors.maxlength" translate>
                    Card Code limit is exceeded.
                  </div>
                </div>
              </div>  
            </div>
    </form>
  </div>
    <div class="form-buttons p-md-8 p-lg-8">
        <button pButton type="button" label="Submit" icon="fa fa-check" [disabled]="!walletTopupForm.valid"
          (click)="addNow()"></button>
      </div>
  
    </div>
  </div>
  <p-toast key="message"></p-toast>