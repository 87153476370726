<div class="track-my-location-container p-grid p-col-12">
  <div class="track-my-location">

    <div class="header p-col-12 p-sm-12">
      <div class="p-col-12 p-md-12 p-lg-6 p-sm-12">
        <a href="{{locationDetails?.tenantInfo.siteName}}" target="_blank">
          <img width="200" src="/assets/images/temp/MI_black_logo.png">
        </a>
      </div>
      <div *ngIf="showTrackMyLocationPage" class="p-col-6 p-md-12 p-lg-6 p-sm-12 p-grid contact-info">
        <div class="p-md-12 p-lg-6 p-sm-6">
          <strong translate>label_contactNumber</strong>:&nbsp;
          <span>{{locationDetails.tenantInfo.tenantPhoneNum}}</span>
        </div>
        <div class="p-md-12 p-lg-6 p-sm-6">
          <strong translate>label_supportEmail</strong>:&nbsp;
          <span translate>{{locationDetails.tenantInfo.tenantEmail}}</span>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="seperator"></div>
    </div>
    <div>
      <div *ngIf="showTrackMyLocationPage" class="location-info p-grid p-col-12 p-md-12 p-lg-12">

        <div class= "p-md-2 p-lg-2 p-sm-12 content">
          <img class="driver-logo" src="{{imageUrl}}">
        </div>

        <div class="p-lg-10 p-sm-12 p-md-10" *ngIf="locationDetails.tourBookingDetailsDto !== undefined && locationDetails.tourBookingDetailsDto !== null" >
            <div class="p-grid p-sm-12 ">
          <div *ngIf="locationDetails.tourBookingDetailsDto.driverInfo !== undefined && locationDetails.tourBookingDetailsDto.driverInfo !== null"
            class="p-md-6 p-lg-3 p-sm-6 content">
            <div class="content-header" translate>label_driver</div>
            <div class="content-info">
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.firstName}}
                {{locationDetails.tourBookingDetailsDto.driverInfo.lastName}}</div>
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.numCountryCode}}-{{locationDetails.tourBookingDetailsDto.driverInfo.phoneNum}}</div>
              <p class="data-info">{{locationDetails.tourBookingDetailsDto.driverInfo.email}}</p>
              <div class="star">
                <span *ngFor="let star of maxRating;let idx = index">
                  <i *ngIf="idx < locationDetails.tourBookingDetailsDto.driverInfo.driverRating" class="fa fa-star active"></i>
                  <i *ngIf="idx >= locationDetails.tourBookingDetailsDto.driverInfo.driverRating" class="fa fa-star"></i>
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="locationDetails.tourBookingDetailsDto.passengerInfo !== undefined && locationDetails.tourBookingDetailsDto.passengerInfo !== null"
            class="p-md-6 p-lg-3 p-sm-6 content">
            <div class="content-header" translate>label_passenger</div>
            <div class="content-info">
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.firstName}}
                {{locationDetails.tourBookingDetailsDto.passengerInfo.lastName}}</div>
              <div class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.numCountryCode}}-{{locationDetails.tourBookingDetailsDto.passengerInfo.phoneNum}}</div>
              <p class="data-info">{{locationDetails.tourBookingDetailsDto.passengerInfo.email}}</p>
            </div>
          </div>
          <div *ngIf="locationDetails.tourBookingDetailsDto.vehicleInfo!== undefined && locationDetails.tourBookingDetailsDto.vehicleInfo !== null"
            class="p-md-6 p-lg-3 p-sm-12 content mr-bottom-11">
            <div class="content-header" translate>label_car_details</div>
            <div class="content-info">
              <table>
                <tbody>
                  <tr>
                    <td translate>label_car_type_with_colon </td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleType}} </td>
                  </tr>
                  <tr>
                    <td translate>label_model_with_colon</td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.makeModel}}</td>
                  </tr>
                  <tr>
                    <td translate>label_vehicle_type_with_colon</td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleType}} </td>
                  </tr>
                  <tr>
                    <td translate>label_plate_number_with_colon </td>
                    <td class="data-info">{{locationDetails.tourBookingDetailsDto.vehicleInfo.vehicleNumber}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div *ngIf="locationDetails.tourBookingDetailsDto.tourBookingInfo !== undefined && locationDetails.tourBookingDetailsDto.tourBookingInfo !== null"
            class="p-md-6 p-lg-3 p-sm-12 content">
            <div class="content-header" translate>label_trip_details</div>
            <div class="content-info">
              <p>
                <i class="fa fa-circle start mr-right-5"></i>{{locationDetails.tourBookingDetailsDto.tourBookingInfo.pickupLocationName}}</p>
              <p>
                <i class="fa fa-circle end mr-right-5"></i>{{locationDetails.tourBookingDetailsDto.tourBookingInfo.dropLocationName}}</p>
              <div>
                <span translate>label_status_with_colon</span>
                <span class="data-info" [ngStyle]="tourStatusColor">{{tourStatus}}</span>
              </div>
             
              
            </div>
          </div>

         
        </div>
        </div>
        <div *ngIf="emergecyrequestedAt"
        class="p-md-12 p-lg-12 p-sm-12 content">
          <!-- <div class="content-header" translate>label_trip_details</div> -->
             <div class="content-info">
         
              <div>
                <span translate>label_emergency_requested_at</span>
                <span class="data-info">{{ emergecyrequestedAt}}</span>
              </div>
              
        </div>
      </div>
      </div>

      <!-- Map -->
      <div class="p-col-12 p-md-12 p-lg-12 p-sm-12 map-container">
        <div id="trackLocationMap" class="map"></div>
      </div>

    </div>

    <div class="p-grid p-col-12 p-md-12 p-lg-12 p-sm-12" *ngIf="(locationDetails?.tenantInfo.appStoreUrl !== '' &&locationDetails?.tenantInfo.appStoreUrl !== null) || locationDetails?.tenantInfo.playStoreUrl !== '' &&locationDetails?.tenantInfo.playStoreUrl !== null">
      <div class="p-col-6 p-md-12 p-lg-6 p-sm-12 text-center">
        <span translate>lable_for_track_my_location_text</span>
      </div>
      <div class="p-grid p-col-6 p-md-12 p-lg-6 p-sm-12">
        <div class="p-col-1 p-md-1 p-lg-1"></div>
        <div class="p-col-5 p-md-5 p-lg-5 p-sm-12" *ngIf="locationDetails.tenantInfo.appStoreUrl !== '' &&locationDetails.tenantInfo.appStoreUrl !== null">
          <a href="{{locationDetails.tenantInfo.appStoreUrl}}" target="_blank">
            <img src="/assets/images/temp/app_store.png">
          </a>
        </div>

        <div class="p-col-5 p-md-5 p-lg-5 p-sm-12" *ngIf="locationDetails.tenantInfo.playStoreUrl !== '' &&locationDetails.tenantInfo.playStoreUrl !== null">
          <a href="{{locationDetails.tenantInfo.playStoreUrl}}" target="_blank">
            <img src="/assets/images/temp/play_store.png">
          </a>
        </div>
        <div class="p-col-1 p-md-1 p-lg-1 p-sm-12"></div>
      </div>
    </div>

    <div class=" p-col-12 p-md-12 p-lg-12">
      <div class="seperator"></div>
    </div>

    <div class=" p-col-12 p-md-12 p-lg-12 p-sm-12">
      <div class="p-grid p-col-6 p-md-12 p-lg-6 p-sm-12 text-center">        
        <div class="p-col-1 p-md-1 p-lg-1 p-sm-2" *ngIf="locationDetails?.tenantInfo.facebookUrl !== '' && locationDetails?.tenantInfo.facebookUrl !== null">
          <a href="{{locationDetails.tenantInfo.facebookUrl}}" target="_blank">
            <img src="/assets/images/temp/footer_fb_icon.png">
          </a>
        </div>

        <div class="p-col-1 p-md-1 p-lg-1 p-sm-2" *ngIf="locationDetails?.tenantInfo.twitterUrl !== '' && locationDetails?.tenantInfo.twitterUrl !== null">
          <a href="{{locationDetails.tenantInfo.twitterUrl}}" target="_blank">
            <img src="/assets/images/temp/footer_twitter_icon.png">
          </a>
        </div>        
      </div>
    </div>
  </div>

</div>

<div *ngIf="!showTrackMyLocationPage" class="track-my-location-error">
  {{errorMessage}}
</div>