import { DestinationModel } from './destination-model';
export class TaxiBookingModel {
  tenantId = '';
  driverId = '';
  serviceTypeId = '';
  partnerId = '';
  partnerType = '';
  numCountryCode = '';
  phoneNum = '';
  passengerName = '';
  vehicleTypeId = '';
  vehicleId = '';
  packageId = '';
  bookingStatus = '';
  sourceLatitude = '';
  sourceLongitude = '';
  destinationLatitude = '';
  destinationLongitude = '';
  pickupLocationName = '';
  dropLocationName = '';
  pickupDateTime = 0;
  vip = false;
  vehicleAutomatic = false;
  withFuel = false;
  note = '';
  email = '';
  bookLater = true;
  passengerId = '';
  bookingDropPoints: DestinationModel[] = [];
  corporateBooking = false;
  roomNum = '';
  quoteAmount = '';
  paymentMode = null;
  passengerVehicleModel = '';

  constructor(option?: {
    bookLater: boolean,
    passengerId: string,
    tenantId: string,
    driverId?: string,
    serviceTypeId: string,
    partnerId: string,
    partnerType: string,
    numCountryCode: string,
    phoneNum: string,
    passengerName: string,
    vehicleTypeId: string,
    vehicleId: string,
    packageId: string,
    bookingStatus: string,
    sourceLatitude: string,
    sourceLongitude: string,
    destinationLatitude: string,
    destinationLongitude: string,
    pickupLocationName: string,
    dropLocationName: string,
    pickupDateTime: number,
    vip?: boolean,
    vehicleAutomatic: boolean,
    withFuel?: boolean,
    note?: string,
    email: string,
    bookingDropPoints: DestinationModel[],
    corporateBooking?: boolean,
    roomNum?: string,
    quoteAmount?: string,
    paymentMode?: string;
    passengerVehicleModel?: string;
  }) {
    if (option) {
      this.paymentMode = option.paymentMode;
      this.passengerId = option.passengerId;
      this.bookLater = option.bookLater;
      this.tenantId = option.tenantId;
      this.driverId = option.driverId;
      this.serviceTypeId = option.serviceTypeId;
      this.partnerId = option.partnerId;
      this.partnerType = option.partnerType;
      this.numCountryCode = option.numCountryCode;
      this.phoneNum = option.phoneNum;
      this.passengerName = option.passengerName;
      this.vehicleTypeId = option.vehicleTypeId;
      this.vehicleId = option.vehicleId;
      this.packageId = option.packageId;
      this.bookingStatus = option.bookingStatus;
      this.sourceLatitude = option.sourceLatitude;
      this.sourceLongitude = option.sourceLongitude;
      this.destinationLatitude = option.destinationLatitude;
      this.destinationLongitude = option.destinationLongitude;
      this.pickupLocationName = option.pickupLocationName;
      this.dropLocationName = option.dropLocationName;
      this.pickupDateTime = option.pickupDateTime;
      this.vip = option.vip;
      this.vehicleAutomatic = option.vehicleAutomatic;
      this.withFuel = option.withFuel;
      this.note = option.note;
      this.bookingDropPoints = option.bookingDropPoints;
      this.email = option.email;
      this.corporateBooking = option.corporateBooking;
      this.roomNum = option.roomNum;
      this.quoteAmount = option.quoteAmount;
      this.passengerVehicleModel = option.passengerVehicleModel;
    }
  }
}
