export class EmployeeUserDetailsModel {
  referrenceNumber = '';
  departmentId = '';
  profileImage = '';
  vip = true;
  constructor(option?: {
    referrenceNumber: string,
    departmentId: string,
    profileImage: string,
    vip: boolean,
  }) {

    if (option) {
      this.referrenceNumber = option.referrenceNumber;
      this.departmentId = option.departmentId;
      this.profileImage = option.profileImage;
      this.vip = option.vip;
    }
  }
}
