<div class="form-container">
    <form [formGroup]='chagePasswordForm'>
        <div>
            <div class="p-grid">
                <div class="form-field p-col-12 p-md-12 p-lg-12">
                    <div class="{{formMessageTextColor}} alert alert-danger" >
                        <div *ngFor="let ErrorMessage of changePasswordErrors['general']">
                            <div *ngIf="ErrorMessage !== null">
                                {{ErrorMessage.message}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-grid p-col-12">
                <div class="form-field p-col-12">
                    <div class="form-label">
                        <label for="oldPassword" translate>label_old_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-show">
                        <input pPassword type="{{passwordType}}" maxlength = "20" style="width: 100%;" appNoWhiteSpace formControlName="oldPassword" />
                        <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()">
                            <i *ngIf="passwordType !== 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="passwordType === 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors" *ngIf="chagePasswordForm.controls.oldPassword.errors  && (chagePasswordForm.controls.oldPassword.dirty || chagePasswordForm.controls.oldPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.required" translate>
                            error_old_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.minlength" translate>
                            password_too_short
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.oldPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>

                <div class="form-field p-col-12">
                    <div class="form-label">
                        <label for="newPassword" translate>label_new_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-show">
                        <input pPassword type="{{passwordTypeNew}}" maxlength = "20" style="width: 100%;" appNoWhiteSpace formControlName="newPassword" (keyup)="passwordNotMatched()" (focusout)="passwordNotMatched()"/>
                        <span class="eye-icon sign-in-eye" (click)="tooglePasswordType1()">
                            <i *ngIf="passwordTypeNew !== 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="passwordTypeNew === 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors" *ngIf="chagePasswordForm.controls.newPassword.errors  && (chagePasswordForm.controls.newPassword.dirty || chagePasswordForm.controls.newPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.required" translate>
                            error_new_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.minlength" translate>
                            password_too_short
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>

                <div class="form-field p-col-12">
                    <div class="form-label">
                        <label for="newConfirmPassword" translate>label_new_confirm_password</label>
                        <label class="asterisk">*</label>
                    </div>
                    <div class="form-control password-show">
                        <input type="{{passwordTypeConfirm}}" pPassword maxlength = "20" style="width: 100%;" appNoWhiteSpace formControlName="newConfirmPassword" (keyup)="passwordNotMatched()" (focusout)="passwordNotMatched()"/>
                        <span class="eye-icon sign-in-eye" (click)="tooglePasswordType2()">
                            <i *ngIf="passwordTypeConfirm !== 'password'" class="fa fa-eye-slash" aria-hidden="true"></i>
                            <i *ngIf="passwordTypeConfirm === 'password'" class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div class="form-errors" *ngIf="chagePasswordForm.controls.newConfirmPassword.errors  && (chagePasswordForm.controls.newConfirmPassword.dirty || chagePasswordForm.controls.newConfirmPassword.touched)">
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.required" translate>
                            error_new_confirm_password_required
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.minlength" translate>
                            password_too_short
                        </div>
                        <div [hidden]="!chagePasswordForm.controls.newConfirmPassword.errors.maxlength" translate>
                            exceed_max_length
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <div class="form-buttons">
                    <button pButton type="button" label="Save" icon="fa fa-check" [disabled]="(chagePasswordForm.valid===false || validForm===false)" (click)="doChangePassword()"></button>
                    <button class="negative-button" pButton type="button" icon="fa fa-close" label="Cancel" (click)="onCancel()"></button>
                </div>
            </div>
        </div>
    </form>
</div>
<p-toast key="errorMessage"></p-toast>