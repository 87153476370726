<div class="ui-g panel-div flexbox ui-g-nopad trip-details" *ngIf="employeeTripDetails!==undefined">
  <div class="ui-g-12 ui-md-12 ui-lg-12 ui-g-nopad position-relative">
    <div class="ui-g">
      <div class="ui-g-6">
        <span class="info-label" translate>label_tripID</span> : {{employeeTripDetails.bookingCode}}
      </div>
      <!-- <div class="ui-g-6">
        <span class="info-label" translate>service_type </span> : {{employeeTripDetails.serviceType}}
      </div> -->
      <div class="ui-g-6">
        <span class="info-label" translate>label_charge_code</span> : {{employeeTripDetails.chargeCode}}
      </div>
      <div class="ui-g-6" *ngIf="bookingApprovalStatus">
        <span class="info-label" translate>label_qoutation_fare_estimate </span> : {{employeeTripDetails.estimateFare}}
      </div>
      <div class="ui-g-6">
        <span class="info-label" translate>label_pickup_date_time_without_col</span> :
        {{employeeTripDetails.pickupdatetime | datetimeformat}}
      </div>
      <div class="ui-g-6" *ngIf="bookingApprovalStatus && employeeTripDetails.note!==null">
        <button type="button" (click)="showDialog()" pButton icon="fa-external-link-square" label="View Note"></button>
      </div>
      <div class="ui-g-12">
        <span class="pickup-circle">&#9673;</span> {{employeeTripDetails.pickupLocationName}}
      </div>
      <div class="ui-g-12">
        <span class="drop-circle">&#9673;</span> {{employeeTripDetails.dropLocationName}}
      </div>

    </div>
    <div class="ui-g-12">
      <div class="ui-g-4" *ngIf="bookingApprovalStatus">
        <button pButton type="button" [label]="labelReject" icon="fa-thumbs-o-down" (click)="onApprove('Rejected')"></button>
      </div>
      <div class="ui-g-4" *ngIf="bookingApprovalStatus">
        <button pButton type="button" [label]="labelApprove" icon="fa-thumbs-o-up" class="ui-button-success" (click)="onApprove('Approved')"></button>
      </div>
      <div class="ui-g-4" *ngIf="bookingApprovalStatus === false">
        <button pButton type="button" [label]="labelViewDetails" (click)="onViewDetails(employeeTripDetails)"></button>
      </div>
    </div>
  </div>
</div>
<div class="ui-g-12 panel-div flexbox ui-g-nopad" *ngIf="employeeTripLength <= 0">
  <div class="ui-g-12">
    Not on ride
  </div>
</div>
<p-dialog header="Note" [(visible)]="display" modal="modal" [style]="{'width': '300px'}" [responsive]="true">
  <p *ngIf="employeeTripDetails.note!==null">
    {{employeeTripDetails.note}}
  </p>
</p-dialog>
<p-toast key="message"></p-toast>