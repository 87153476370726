import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppSettings } from '../../modules/shared/app.settings';
import { CommonBindingDataService } from '../../modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  usernamePlaceholder;
  passwordPlaceHolder;
  signInLabel;
  displaySignInButton: Boolean = false;
  @Output() signInEmitter: EventEmitter<any> = new EventEmitter();
  @Output() forgotPasswordEmitter: EventEmitter<any> = new EventEmitter();

  signInForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  constructor(
    private formBuilder: FormBuilder,
    private commonBindingDataService: CommonBindingDataService
  ) { }

  ngOnInit() {
    this.usernamePlaceholder = this.commonBindingDataService.getLabel('Username');
    this.passwordPlaceHolder = this.commonBindingDataService.getLabel('Password');
    this.signInLabel = this.commonBindingDataService.getLabel('label_sign_in');
    this.signInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
    });
  }

  doSignIn() {
    this.signInEmitter.emit(this.getLoginObject());
  }

  getLoginObject() {
    let signInData = {};
    if (this.signInForm.controls.username.errors === null) {
      signInData = {
        'email': (this.signInForm.controls.username.value).trim(),
        'password': this.signInForm.controls.password.value,
        'tenantId': AppSettings.TENANT
      };
    } else {
      signInData = {
        'username': (this.signInForm.controls.username.value).trim(),
        'password': this.signInForm.controls.password.value,
        'tenantId': AppSettings.TENANT
      };
    }
    return signInData;
  }

  onForgotPassword() {
    this.forgotPasswordEmitter.emit();
  }

  eventHandler(event) {
    if (event.keyCode === AppSettings.ENTER_KEY) {
      this.signInEmitter.emit(this.getLoginObject());
    }
  }

}
