<div class="page-header-menu">
  <div class="company-logo header-left-panel">
    <img style="max-width: 119px;" src="/assets/images/temp/mobisoft-final-logo.png">
  </div>
  <div class="header-right-panel p-grid p-jc-end  p-ai-center vertical-containe">
    <div class="p-col-fixed p-pb-0" >
      <div class="top-bar-item">
        <a (click)="onSideMenuClick($event)" class="hamburger-menu">
          <i class="fa fa-bars"></i>
        </a>
      </div>
    </div>
    <div class="p-col p-pb-0">
      <div class="nav-right-menu p-grid p-jc-end  p-ai-center vertical-containe">
      <div class="p-col-fixed p-pb-0">
        <div class=" top-bar-item notifications-bell" (click)="getNotificationListAndToggle($event)">
          <a class="nav-notifications-icon">
            <i class="fa fa-bell-o" aria-hidden="true"></i>
            <div class="new-notification-count" *ngIf="unReadNotificatonsCount">
              {{unReadNotificatonsCount}}
            </div>
          </a>
        </div>
      </div>

        <div class="profile-menu-button p-col-fixed p-pb-0">
          <p-menu #menu popup="popup" [model]="items"></p-menu>
          <a (click)="menu.toggle($event)">
            <img class="user-photo" src="{{profileUrl}}" onerror="src='/assets/images/temp/default_profile.png'">
            <div style="display: inline-block;">{{userFullName}} <i class="fa fa-angle-down" aria-hidden="true"></i> &nbsp;</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="hasShowNotificationBar" position="right">
  <div class="notification-container">
    <div class="header">{{notificationLabel}}</div>
    <div class="content">
      <app-notification-mg1 [notifications]="notifications" (loadMoreNotification)="loadMoreNotification()">
      </app-notification-mg1>
    </div>
  </div>
</p-sidebar>

<p-dialog modal="true" styleClass="add-terms-popup" [style]="{'width': '600px'}" [(visible)]="changePasswordPopup">
  <p-header>
    Change Password
  </p-header>
  <app-change-password [(changePasswordPopup)]="changePasswordPopup"
    (isShowChangePassword)="onCancelChangePassword($event)"></app-change-password>
</p-dialog>

<p-dialog modal="false" styleClass="edit-profile-popup" position="center center" responsive="true"
  [(visible)]="editProfilePopup">
  <p-header>
    Edit Profile
  </p-header>
  <app-edit-profile [(editProfilePopup)]="editProfilePopup"  (showProfilePopup)="onCancelEditProfile($event)">
  </app-edit-profile>
</p-dialog>
<!-- <p-messages [(value)]="message" [closable]="false"></p-messages> -->
 <p-toast key="commonToast"></p-toast>
