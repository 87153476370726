import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonBindingDataService } from './../../services/common-binding-data.service';

@Component({
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html'
})
export class CommentReplyComponent implements OnInit {
  @Input() tripCommentDetails;
  @Input() userType;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  replyText = '';
  constructor(private commonService: CommonBindingDataService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() { }

  doRply() {
    const input = {
      driverId: this.tripCommentDetails.driverId,
      passengerId: this.tripCommentDetails.passengerId,
      reply: this.replyText
    };
    (this.userType === 'driver') ? (input.passengerId = null) : (input.driverId = null);
    this.commonService.commentReply(input, this.tripCommentDetails.tripId).subscribe((results) => {
      this.replyText = '';
      this.isPopSave.emit(results);
    }, (error) => {
      console.log(error);
    });
  }

  cancel() {
    this.replyText = '';
    this.isPopCancel.emit();
  }

}
