export class EditablePackagesModel {
  tenantId = '';
  serviceAreaId = '';
  serviceTypeId = '';
  packageName = '';
  vehicleTypeId = '';
  distance = 0;
  minutes = 0;
  baseRate = 0;
  additionalDistanceRate = 0;
  additionalMinuteRate = 0;
  rideTimeFare = 0;
  waitTimeFare = 0;
  cancellationRate = 0;
  applicableFrom = 0;
  transmissionType = '';
  transmissionTypeCode = '';

  constructor(option?: {
    tenantId?: string;
    serviceAreaId: any;
    serviceTypeId: any;
    packageName: string;
    vehicleTypeId: any;
    distance: number;
    minutes: number;
    baseRate: number;
    additionalDistanceRate: number;
    additionalMinuteRate: number;
    rideTimeFare: number;
    waitTimeFare: number;
    cancellationRate: number;
    applicableFrom: number;
    transmissionType: string;
    transmissionTypeCode?: string;
  }) {

    if (option) {
      this.tenantId = option.tenantId;
      this.serviceAreaId = option.serviceAreaId;
      this.serviceTypeId = option.serviceTypeId;
      this.packageName = option.packageName;
      this.vehicleTypeId = option.vehicleTypeId;
      this.distance = option.distance;
      this.minutes = option.minutes;
      this.baseRate = option.baseRate;
      this.additionalDistanceRate = option.additionalDistanceRate;
      this.additionalMinuteRate = option.additionalMinuteRate;
      this.rideTimeFare = option.rideTimeFare;
      this.waitTimeFare = option.waitTimeFare;
      this.cancellationRate = option.cancellationRate;
      this.applicableFrom = option.applicableFrom;
      this.transmissionType = option.transmissionType;
      this.transmissionTypeCode = option.transmissionTypeCode;
    }

  }

}
