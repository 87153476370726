import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoWhiteSpace]'
})
export class WhiteSpaceDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    return (e.key !== ' ')
  }
}
