<div class="no-content" *ngIf="notifications.length === 0">
  {{noNotificationFoundLabel}}
</div>

<div class="scroll-container">
  <div class="p-grid notification-card p-col-nopad" *ngFor="let notification of notifications">
    <div class="p-grid p-col-12 notification-time ">
      <div class="p-col-6 date p-col-nopad">
        {{notification.createdAt| dateformat}}
      </div>
      <div class="p-col-6 ago p-col-nopad">
        {{notification.createdAt| ago }}
      </div>

      <div class="p-col-12 message">
        {{notification.message}}
      </div>
    </div>
  </div>

  <div class="p-col-12" *ngIf="notifications.length > 0">
    <button pButton class="assign-btn load-more-btn" type="button" [label]="labelLoadMore" (click)="onLoadMore()"></button>
  </div>

</div>