import { Component, OnChanges, Input } from '@angular/core';
import { Message } from 'primeng/api';
import { AppSettings } from 'app/modules/shared/app.settings';
declare const google: any;

@Component({
  selector: 'app-track-location',
  templateUrl: './track-location.component.html'
})
export class TrackLocationComponent implements OnChanges {
  @Input() driverDetails;
  lableDriverLocation;
  driverLocationMap: any;

  marker: any = null;
  markers: any = [];
  message: Message[] = [];

  map: any;

  options = AppSettings.MAP_OPTION;

  constructor() { }

  ngOnChanges() {
    this.trackDriverLocation();
  }

  trackDriverLocation() {
    this.clearMarker();
    if (this.driverDetails !== undefined && this.driverDetails !== null && this.driverDetails !== '') {
      this.options.center.lat = this.driverDetails.latitude;
      this.options.center.lng = this.driverDetails.longitude;
      this.driverLocationMap = new google.maps.Map(document.getElementById('driverLocationMap'), this.options);
      const latLng = {
        lat: parseFloat(this.driverDetails.latitude),
        lng: parseFloat(this.driverDetails.longitude)
      };

      this.marker = new google.maps.Marker({
        position: latLng,
        icon: '/assets/images/temp/taxi_busy.png',
        map: this.map,
      });

      this.markers.push(this.marker);
      setTimeout(() => {
        google.maps.event.trigger(this.driverLocationMap, 'resize');
        this.driverLocationMap.setCenter(latLng);
        this.markers[0].setMap(this.driverLocationMap);
      }, 500);
    }
  }

  displayMessage(msgObj, severity) {
    this.message = [];
    msgObj.general.forEach(element => {
      this.message.push({
        severity: severity,
        summary: severity,
        detail: element.message
      });
    });
  }


  clearMarker() {
    this.driverLocationMap = null;
    if (this.marker) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
      this.marker = null;
    }
  }

}
