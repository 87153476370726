import { Injectable } from '@angular/core';


@Injectable()
export class ErrorBindingService {

  constructor() { }

  onAPIValidationError(error, errorList) {
    for (const k in error) {
      if (error.hasOwnProperty(k)) {
        errorList[0][k] = true;
        errorList[k] = error[k];
        this.hideErrorMessage(errorList, k);
        break;
      }
    }
  }



  onAPIValidationSuccess(response, errorList) {
    for (const k in response) {
      if (response.hasOwnProperty(k)) {
        errorList[0][k] = true;
        errorList[k] = response[k];
        this.hideErrorMessage(errorList, k);
        break;
      }
    }
  }

  hideErrorMessage(errorList, k) {
    setTimeout(() => {
      errorList[0][k] = false;
    }, 5000);
  }

}
