<div class="ui-g-12 ui-lg-12 ui-md-12">
  <div class="form-field ui-g-12 ui-md-12 ui-lg-12">
    <div class="form-label">
      <label for="quotationamount" translate>label_reply</label>
    </div>
    <div class="form-control">
      <textarea type="text" pInputText [(ngModel)]="replyText" type="text" pInputText>
      </textarea>
    </div>
  </div>

  <div class="form-buttons ui-g-12">
    <button pButton type="button" label="Reply" icon="fa-reply" (click)="doRply()"></button>
    <button class="negative-button" pButton type="button" icon="fa-close" label="Cancel" (click)="cancel()"></button>
  </div>
</div>