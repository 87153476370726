import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { MiGrid2Component } from 'app/modules/shared/components/mi-grid-2/mi-grid-2.component';
import { formatDateCell } from '../../../shared/utility-functions/grid';

@Component({
  selector: 'app-log-deails',
  templateUrl: './log-deails.component.html',
})
export class LogDeailsComponent implements OnInit {

  id: any;
  fromDate: Date = null;
  toDate: Date = null;
  params: any = '';
  dateFormat;
  columns: any = [];
  url = '/secure/auditlogs/grid/data/';

  @ViewChild(MiGrid2Component, { static: true }) gridRef2: MiGrid2Component;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonBindingDataService: CommonBindingDataService,

  ) { }

  ngOnInit() {

    this.id = this.route.snapshot.queryParams['id'];
    this.dateFormat = this.commonBindingDataService.getDateFormat();
    this.url = '/secure/auditlogs/grid/data/' + this.id;

    this.columns.push({
      field: 'createdAt', header: this.commonBindingDataService.getLabel('label_date'),
      sort: true, resize: true, width: 150, cellRenderer: (i) => (formatDateCell(parseInt(i, 10)))
    });
    this.columns.push({
      field: 'action', header: this.commonBindingDataService.getLabel('label_action'),
      sort: true, resize: true, width: 130
    });
    this.columns.push({
      field: 'dataBeforeAction', header: this.commonBindingDataService.getLabel('label_data_before_action'),
      sort: false, resize: true, cellRendererHtml: (i) => (this.colDataBeforeActionDetails(i))
    });
    this.columns.push({
      field: 'dataAfterAction', header: this.commonBindingDataService.getLabel('label_data_after_action'),
      sort: false, resize: true, cellRendererHtml: (i) => (this.colDataAfterActionDetails(i))
    });


  }

  colDataBeforeActionDetails(params) {
    let lblActivate = '';
    // if (params.dataBeforeAction) {
    //   lblActivate = `<div title="params.dataBeforeAction ">` + params.dataBeforeAction + `</div>`;
    // }
    if (params.dataBeforeAction) {
      lblActivate = `<div class="hand-pointer" title='${params.dataBeforeAction}'> ${params.dataBeforeAction} </div>`;
    }
    const eDiv = document.createElement('div');
    eDiv.setAttribute('class', 'grid-comman-col');
    eDiv.innerHTML = lblActivate;
    return eDiv;
  }

  colDataAfterActionDetails(params) {
    let lblActivate = '';
    if (params.dataAfterAction) {
      lblActivate = `<div class="hand-pointer" title='${params.dataAfterAction}'> ${params.dataAfterAction} </div>`;
    }
    const eDiv = document.createElement('div');
    eDiv.setAttribute('class', 'grid-comman-col');
    eDiv.innerHTML = lblActivate;
    return eDiv;
  }

  changeFilter(event) {
    //
    this.params = '';
    if (this.fromDate !== undefined && this.fromDate !== null && this.toDate !== undefined || this.toDate !== null) {
      this.params += ('?fromDate=' + this.fromDate.getTime() + '&toDate='
        + this.commonBindingDataService.addLapseTimeInEndDate(this.toDate.getTime()) + '&');
    }
    this.params = this.params.slice(0, -1);
    this.reloadGrid();
  }

  reloadGrid() {
    // this.gridRef.changeDatasource(this.url + this.params, this.gridColumn);
    this.gridRef2.updateUrl(this.url + this.params);

  }

}
