import { ActivatedRoute, Router } from '@angular/router';
import { SalesQuotationBookingDetailsService } from './../../../sales-quotation/services/sales-quotation-booking-details.service';
import { Message } from 'primeng/api';
import { AppSettings } from './../../../shared/app.settings';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-upload-quote',
  templateUrl: './upload-quote.component.html'
})
export class UploadQuoteComponent implements OnInit {
  @Output() reloadGrid: EventEmitter<any> = new EventEmitter();
  quotationId: any;
  display = false;
  quotationURL = '';
  uploadedQuotationFileName = '';
  uploadedQuotationFileId = '';
  quotationData: any;
  message: Message[] = [];
  quoationIdFromListView = '';
  labelUploadQuotePdf = '';

  uploadQuotaError;

  uploadQuotationForm = new FormGroup({
    quotationAmount: new FormControl(),
    quotationNote: new FormControl()
  });

  constructor(private salesQuotationService: SalesQuotationBookingDetailsService,
    private route: ActivatedRoute,
    private commonService: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.labelUploadQuotePdf = this.commonService.getLabel('lable_upload_quote_pdf');
    this.uploadQuotaError = {
      quotationAmount: false,
      quotationNote: false,
      file: false,
    };

    this.uploadQuotationForm = this.formBuilder.group({
      quotationAmount: ['', [Validators.required,
      Validators.pattern(AppSettings.AMOUNT_PATTERN)]],
      quotationNote: ['', [Validators.required]]
    });
  }

  onUpload(response) {
    this.quotationURL = AppSettings.GET_FILE_THUMB_URL + response.fileId;
    this.uploadedQuotationFileId = response.fileId;
    this.uploadedQuotationFileName = response.originalFileName;
  }


  onSave() {
    this.quotationData = {
      quotationFile: this.uploadedQuotationFileId,
      quotationAmount: this.uploadQuotationForm.controls.quotationAmount.value,
      description: this.uploadQuotationForm.controls.quotationNote.value
    };

    if (this.quoationIdFromListView !== undefined) {
      this.quotationId = this.quoationIdFromListView;
    } else {
      this.quotationId = this.route.snapshot.queryParams['id'];
    }

    this.salesQuotationService.uploadQuotation(this.quotationId, this.quotationData).subscribe(quotationUploadResponse => {
      this.message = [];
      this.message.push({ severity: 'success', summary: 'Success Message', detail: quotationUploadResponse.general[0].message });
      this.waitToCloseMessage(quotationUploadResponse);
      this.uploadQuotationForm.reset();
      this.uploadedQuotationFileName = '';
    }, (error) => {

      this.message = [];
      this.message.push({ severity: 'error', summary: 'Error Message', detail: error.general[0].message });
    });
  }

  waitToCloseMessage(quotationUploadResponse) {
    setTimeout(() => {
      this.display = false;
      this.router.navigate(['/sales-quotation']);
      this.reloadGrid.emit(quotationUploadResponse);
    }, 2000);
  }

  onCancel() {
    this.uploadedQuotationFileName = '';
    this.uploadedQuotationFileId = '';
    this.uploadQuotationForm.reset();
    this.display = false;
  }

  showDialog(id?) {
    this.quoationIdFromListView = id;
    this.display = true;
  }

  doValidSave() {
    if (this.uploadQuotationForm.valid && this.uploadedQuotationFileId !== '') {
      return false;
    } else {
      return true;
    }
  }
}
