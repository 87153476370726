<div class="signin-box-container ">
  <div class="signin-box-inner-container">
   <div class="p-grid">
    <div class="logo-box p-col-12 p-md-6 p-lg-6">
      <div>
        <div class="form-field p-col-4">
          <img src='/assets/images/temp/mobisoft-final-logo.png'>
        </div>
        <div class="form-field p-col-12 sign-in-text-sm">
          <h2>{{signInWelcomeTextLabel}}</h2>
        </div>

        <div class="form-field p-col-11">
          <h4>{{signInDescription}}</h4>
        </div>
      </div>
    </div>

    <div class="signin-box p-col-12 p-md-5 p-lg-5">
      <p-panel class="p-panel1">
        <div class="form-field p-col-12">
          <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["general"]'>
            <div *ngFor="let ErrorMessage of loginErrors['general']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>
        <div class="form-field p-col-12">
          <div class="form-errors alert alert-danger" [hidden]='!loginErrors[0]["email"]'>
            <div *ngFor="let ErrorMessage of loginErrors['email']">
              {{ErrorMessage.message}}
            </div>
          </div>
        </div>

        <div class="form-field p-col-12">
          <div class="form-field p-col-12 switch-btn">Login</div>
          <div class="form-field p-col-12 selected-menu"></div>
        </div>

        <app-login (signInEmitter)="doSignIn($event)" (forgotPasswordEmitter)="forgotPassword()"></app-login>
        <div class="ui-helper-clearfix"></div>
      </p-panel>

    </div>
    </div>
  </div>
</div>


<p-dialog modal="false" styleClass="edit-popup extend-width" position="center center" responsive="true" [(visible)]="forgotPopup"
  [closable]="true">
  <p-header>{{labelResetPassword}} </p-header>
  <app-forgot-password (popClose)="popClose($event)" (popSave)="popSave($event)"></app-forgot-password>
</p-dialog>

<p-dialog modal="false" [style]="{'width': '500px'}" styleClass="edit-popup" position="center center" responsive="true" [(visible)]="otpPopup" [closable]="true">
  <p-header>{{labelValidateOtp}}</p-header>
  <app-otp-verifiy [email]="otpEmail" [phone]="otpPhone" (otpPopClose)="otpPopClose($event)" (otpPopSave)="otpPopSave($event)"></app-otp-verifiy>
</p-dialog>

<p-dialog modal="false" styleClass="edit-popup" position="center center" responsive="true" [(visible)]="setPasswordPopup"
  [closable]="true">
  <p-header>{{labelResetPassword}}</p-header>
  <app-set-password [otpId]="otpId" (setPasswordPopClose)="setPasswordPopClose($event)"></app-set-password>
</p-dialog>

