import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-booking-cancelation',
  templateUrl: './booking-cancelation.component.html'
})
export class BookingCancelationComponent implements OnInit {
  @Input() bookingID;
  @Output() isPopSave: EventEmitter<any> = new EventEmitter();
  @Output() isPopCancel: EventEmitter<any> = new EventEmitter();
  message: Message[] = [];
  packageApiError: any = [{
    pickupLocationName: false,
    cancelReason: false
  }];

  bookingCancelform = new FormGroup({
    cancelReason: new FormControl(),
  });
  labelSave;
  labelCancel;
  label_Cancelation;

  constructor(
    private formBuilder: FormBuilder,
    private commonBindingDataService: CommonBindingDataService) { }

  ngOnInit() {
    this.bookingCancelform = this.formBuilder.group({
      cancelReason: ['', [Validators.required]]
    });
    this.labelSave = this.commonBindingDataService.getLabel('label_booking_cancel');
    this.labelCancel = this.commonBindingDataService.getLabel('label_cancel');
    this.label_Cancelation = this.commonBindingDataService.getLabel('label_Cancelation');

  }

  cancelBooking() {
    const data = {
      cancellationReason: this.bookingCancelform.controls.cancelReason.value
    };
    this.commonBindingDataService.cancelBooking(this.bookingID, data).subscribe(results => {
      this.successSave('success', results);
      this.bookingCancelform.reset();
    }, (error) => {

      this.successSave('Error', error);
    });
  }

  successSave(messsage, results) {
    this.message = [];
    this.message.push({ severity: messsage, summary: messsage, detail: results.general[0].message });

    setTimeout(() => {
      this.isPopSave.emit(results);
    }, 2000);
  }

  reset() {
    this.bookingCancelform.reset();
  }

  cancel() {
    this.isPopCancel.emit();
  }

}
