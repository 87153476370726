<div class="p-grid form-container">
  <div class="p-col-12">
    <div class="form-errors alert alert-danger" [hidden]='!packageApiError[0]["pickupLocationName"]'>
      <div *ngFor="let ErrorMessage of packageApiError['pickupLocationName']">
        {{ErrorMessage.message}}
      </div>
    </div>
  </div>

  <form class="p-col-12 p-col-nopad" [formGroup]="bookingCancelform">
    <div class="form-field">
      <div class="form-control">
        <textarea formControlName="cancelReason" [placeholder]="label_Cancelation" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"></textarea>
      </div>
      <div class="form-errors" *ngIf="bookingCancelform.controls.cancelReason.errors  && (bookingCancelform.controls.cancelReason.dirty || bookingCancelform.controls.cancelReason.touched)">
        <div [hidden]="!bookingCancelform.controls.cancelReason.errors.required" translate>
          cancel_reason_req
        </div>
      </div>
    </div>
    <div class="p-col-12 p-col-nopad form-buttons">
      <button class="negative-button" pButton type="button" icon="fa fa-close" [label]="labelCancel" (click)="cancel()"></button>
      <button pButton type="button" [label]="labelSave" icon="fa fa-check" [disabled]="!bookingCancelform.valid" (click)="cancelBooking()"></button>
    </div>
  </form>
</div>

<p-toast key="message"></p-toast>